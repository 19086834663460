import * as React from 'react';
import { Typography, FormHelperText } from '@mui/material/'
import { CartesianGrid, XAxis, YAxis, Tooltip, Bar, BarChart, Legend } from 'recharts'

export default function DailyRecordsBarGraph({machineStatistics, machineName}) {
    if (machineStatistics.length === 0) {

        machineStatistics =  [{
            "Cutting Time": 0,
            "Operating Time": 0,
            "Spindle Time": 0,
            "Running Time": 0,
            "date": "No"   
        },{
            "Cutting Time": 0,
            "Operating Time": 0,
            "Spindle Time": 0,
            "Running Time": 0,
            "date": "Information"   
        },{
            "Cutting Time": 0,
            "Operating Time": 0,
            "Spindle Time": 0,
            "Running Time": 0,
            "date": "Available"   
        }]
    } else {
        machineStatistics = machineStatistics.map( machine => ({
            "Cutting Time": machine.cuttingTime,
            "Operating Time": machine.operatingTime,
            "Spindle Time": machine.spindleTime,
            "Running Time": machine.runningTime,
            "date": machine.date.split(",")[1]
            
        }))
    }
    return (
        <React.Fragment>
            <Typography variant='h5' style={{ fontFamily: 'monospace' }} gutterBottom sx={{ textAlign: 'left' }}>{machineName} Daily Records Bar Graph</Typography>
            <Typography 
                variant='h7' 
                style={{ fontFamily: 'monospace' }} 
                gutterBottom sx={{ textAlign: 'left' }}
            >
                A bar graph showing all available daily records from the given time frame. Time measured in seconds.
            </Typography>
            <BarChart width={1000} height={350} data={machineStatistics}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" />
                <YAxis label={{ value: 'Seconds', angle: -90, position: 'insideLeft', offset: 0 }}/>
                <Tooltip />
                <Legend />
                <Bar dataKey="Cutting Time" fill="#0e4780" />
                <Bar dataKey="Operating Time" fill="#7a5195" />
                <Bar dataKey="Spindle Time" fill="#ffa600" />
                {/* <Bar dataKey="Running Time" fill="#ffa600" /> */}
            </BarChart>
            <FormHelperText sx={{textAlign: 'Left'}}>Total Records: {machineStatistics.length}</FormHelperText>
            <FormHelperText sx={{textAlign: 'Left'}}>One Day: 86,400 seconds.</FormHelperText>
        </React.Fragment>
    )
}