import * as React from 'react';
import { Typography,  TableContainer, Table, TableBody, TableHead, TableRow, Paper, FormHelperText } from '@mui/material/'
import { secondsToHms, StyledTableCell, StyledTableRow } from '../utils'

export default function DailyRecordHLATbl({machineStatistics, machineName}) {
    if (machineStatistics.length === 0) {
        return (
            <React.Fragment>
                <Typography variant='h5' style={{ fontFamily: 'monospace' }} gutterBottom sx={{ textAlign: 'left' }}>Daily Record High, Low and Average Table</Typography>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>{machineName}</StyledTableCell>
                                <StyledTableCell align="left">High</StyledTableCell>
                                <StyledTableCell align="left">Low</StyledTableCell>
                                <StyledTableCell align="left">Average</StyledTableCell>
                                <StyledTableCell align="right">Total Records</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    No information available
                                </StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <FormHelperText style={{ textAlign: 'right' }}>Time format: HH:MM:SS</FormHelperText>
            </React.Fragment>
        )
    } else {

        //Reorder machine statistics in order of cut time, spindle time, operating time and running time
        machineStatistics = [machineStatistics[0], machineStatistics[3], machineStatistics[1], machineStatistics[2]] 

        return (
            <React.Fragment>
                <Typography variant='h5' style={{ fontFamily: 'monospace' }} gutterBottom sx={{ textAlign: 'left' }}>Daily Record High, Low and Average Table</Typography>
                <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 700 }} aria-label="customized table">
                        <TableHead>
                            <TableRow>
                                <StyledTableCell>{machineName}</StyledTableCell>
                                <StyledTableCell align="left">High</StyledTableCell>
                                <StyledTableCell align="left">Low</StyledTableCell>
                                <StyledTableCell align="left">Average</StyledTableCell>
                                <StyledTableCell align="right">Total Records</StyledTableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {machineStatistics.map((stats) => (
                            <StyledTableRow key={stats.type}>
                                <StyledTableCell component="th" scope="row">
                                {stats.type}
                                </StyledTableCell>
                                <StyledTableCell align="left">{secondsToHms(stats.high)}</StyledTableCell>
                                <StyledTableCell align="left">{secondsToHms(stats.low)}</StyledTableCell>
                                <StyledTableCell align="left">{secondsToHms(stats.average)}</StyledTableCell>
                                <StyledTableCell align="right">{stats.totalRecords}</StyledTableCell>
                            </StyledTableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <FormHelperText style={{ textAlign: 'right' }}>Time format: HH:MM:SS</FormHelperText>
            </React.Fragment>
        )
    }
}