import { React, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Typography, Dialog, DialogTitle, DialogContent, Stack, IconButton } from "@mui/material/"
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'

export default function SignOutConfirmationDialog({ loginState, setLoginState, initLoginState }) {
    const navigate = useNavigate()

    const closeSignOutConfirmationDialog = () => {
        navigate('/VisitorSignInMenu')
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/VisitorSignInMenu')
        }, 5000)

        return () => {
            clearTimeout(timer)
        }// eslint-disable-next-line
    }, [])

    return (
        <Dialog
            open={loginState.signoutConfirmationToggle}
            onClose={closeSignOutConfirmationDialog}
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle sx={{ backgroundColor: '#0e4780', color: 'white' }}>
                You have been signed out
                <IconButton
                    size='small'
                    onClick={closeSignOutConfirmationDialog}
                    sx={{ float: 'right', color: 'white' }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers sx={{ display: 'flex', justifyContent: 'center' }}>
                <Stack spacing={2}>
                    <CheckIcon fontSize='large' color='success' sx={{ display: 'flex', alignSelf: 'center' }}/>
                    <Typography sx={{ fontSize: 20, textAlign: 'center' }}>
                        {loginState.message}
                    </Typography>
                    <Typography sx={{ fontSize: 18, textAlign: 'center' }}>Redirecting to main menu...</Typography>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}