import * as React from 'react'
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography} from '@mui/material/'

const GroupErrorTable = ({groupErrors}) => {
    const topFifteenErrors = groupErrors.errors.slice(0, 15)
    var n = 0
    const topFifteenErrorsTotal = topFifteenErrors.reduce((acc, currentObj) => acc + currentObj.Frequency, 0)

    return(
        <React.Fragment>
            <Typography variant='h5' style={{ fontFamily: 'monospace' }} gutterBottom sx={{ textAlign: 'left' }}>Top 15 Errors</Typography>
            <Typography variant='h7' style={{ fontFamily: 'monospace' }} gutterBottom sx={{ textAlign: 'left' }}>Total Errors represented in the top 15: {topFifteenErrorsTotal}.</Typography>
            <TableContainer>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Rank</TableCell>
                            <TableCell align="left">Error Code</TableCell>
                            <TableCell align="left">Description</TableCell>
                            <TableCell align="left">Frequency</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {topFifteenErrors.map( error => (
                            <TableRow
                                key={error.Code}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell>{n += 1}</TableCell>
                                <TableCell component="th" scope="row">
                                    {error.Code}
                                </TableCell>
                                <TableCell align="left">{error.Description}</TableCell>
                                <TableCell align="left">{error.Frequency}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </React.Fragment>
    )
}

export default GroupErrorTable