export default function parseGroupOverview(data) {

    var totalDailyRecords = 0
    var totalErrors = 0
    var totalPrograms = 0

    if (!data.some((machine) => machine.DailyRecords.length > 0)) {
        return { 
            dailyStats: {
            totalRecords: 0,
                groupStats: [],
            },
            errorStats: {
                totalErrors: 0,
                errors: []
            },
            programStats: {
                totalPrograms: 0,
                programs: []
            }
        }
    }

    var totalSpindleTime = 0
    var highSpindleTime = data[0].DailyRecords[0].Spindle_Time
    var lowSpindleTime = data[0].DailyRecords[0].Spindle_Time

    var totalCuttingTime = 0
    var highCuttingTime = data[0].DailyRecords[0].Cutting_Time
    var lowCuttingTime = data[0].DailyRecords[0].Cutting_Time

    var totalRunningTime = 0
    var highRunningTime = data[0].DailyRecords[0].Running_Time
    var lowRunningTime = data[0].DailyRecords[0].Running_Time

    var totalOperatingTime = 0
    var highOperatingTime = data[0].DailyRecords[0].Operating_Time
    var lowOperatingTime = data[0].DailyRecords[0].Operating_Time

    var errorCodeArray = []
    var programArray = []

    data.forEach(machine => {
        machine.DailyRecords.forEach(dailyRecord => {
            totalDailyRecords += 1

            totalOperatingTime = totalOperatingTime + dailyRecord.Operating_Time
            highOperatingTime = checkHighValue(dailyRecord.Operating_Time, highOperatingTime)
            lowOperatingTime = checkLowValue(dailyRecord.Operating_Time, lowOperatingTime)

            totalRunningTime = totalRunningTime + dailyRecord.Running_Time
            highRunningTime = checkHighValue(dailyRecord.Running_Time, highRunningTime)
            lowRunningTime = checkLowValue(dailyRecord.Running_Time, lowRunningTime)

            totalCuttingTime = totalCuttingTime + dailyRecord.Cutting_Time
            highCuttingTime = checkHighValue(dailyRecord.Cutting_Time, highCuttingTime)
            lowCuttingTime = checkLowValue(dailyRecord.Cutting_Time, lowCuttingTime)

            totalSpindleTime = totalSpindleTime + dailyRecord.Spindle_Time
            highSpindleTime = checkHighValue(dailyRecord.Spindle_Time, highSpindleTime)
            lowSpindleTime = checkLowValue(dailyRecord.Spindle_Time, lowSpindleTime)
        })
        machine.Errors.forEach(error => {
            totalErrors += 1
            if (errorCodeArray.some((x) => x.Code === error.Code)) {
                errorCodeArray.forEach(errorObj => {
                    if(errorObj.Code === error.Code) {
                        errorObj.Frequency += 1
                    }
                })
            } else {
                errorCodeArray.push({"Code": error.Code, "Description": error.Description, "Frequency": 1})
            }
        })

        machine.Programs.forEach(prog => {
            totalPrograms += 1
            if (programArray.some((x) => x.Program === prog.Program)) {
                programArray.forEach(programObj => {
                    if (programObj.Program === prog.Program) {
                        programObj.Frequency += 1
                    }
                })
            } else {
                programArray.push({"Program": prog.Program, "Frequency": 1 })
            }
        })
    })

    errorCodeArray = errorCodeArray.sort((a,b) => b.Frequency - a.Frequency)
    programArray = programArray.sort((a,b) => b.Frequency - a.Frequency)

    return {
        dailyStats: {
            totalRecords: totalDailyRecords,
            groupStats: [{
                Total: totalCuttingTime,
                High: highCuttingTime,
                Low: lowCuttingTime,
                Average: parseInt((totalCuttingTime / totalDailyRecords).toFixed(2)),
                Type: 'Cutting Time'
            },{
                Total: totalSpindleTime,
                High: highSpindleTime,
                Low: lowSpindleTime,
                Average: parseInt((totalSpindleTime / totalDailyRecords).toFixed(2)),
                Type: 'Spindle Time'
            },
            {
                Total: totalOperatingTime,
                High: highOperatingTime,
                Low: lowOperatingTime,
                Average: parseInt((totalOperatingTime / totalDailyRecords).toFixed(2)),
                Type: 'Operating Time'
            },
            {
                Total: totalRunningTime,
                High: highRunningTime,
                Low: lowRunningTime,
                Average: parseInt((totalRunningTime / totalDailyRecords).toFixed(2)),
                Type: 'Running Time'
            }],
        },
        errorStats: {
            totalErrors: totalErrors,
            errors: errorCodeArray
        },
        programStats: {
            totalPrograms: totalPrograms,
            programs: programArray
        }
    }
}

function checkHighValue(value, toBeChecked) {
    if (value > toBeChecked) {
        return value
    }

    return toBeChecked
}

function checkLowValue(value, toBeChecked) {
    if (value < toBeChecked) {
        return value
    }

    return toBeChecked
}