import { Paper } from '@mui/material/'
import { DataGrid, GridToolbar } from '@mui/x-data-grid'

const getRowCellBackgroundColor = (params) => {
    const rowData = params.row[params.field]; // Renamed cellData to rowData
    if (rowData) {
        if (rowData.endsWith('-C')) {
            return 'blue-cell'
        } else if (rowData.endsWith('-S')) {
            return 'green-cell'
        } else if (rowData.endsWith('-O')) {
            return 'grey-cell'
        }
    }
    return ''
}


const StatusTable = ({ State }) => {

    return (
        <Paper elevation={1} sx={{ width: '100%', height: '100%', overflow: 'auto' }}>
            <DataGrid 
                rows={State.response.rows} 
                columns={State.response.columns.map((column) => ({ 
                    ...column,
                    cellClassName: (params) => {
                        const cellData = params.row[params.field];
                        if (typeof(cellData) === 'string') {
                            if (cellData && cellData.endsWith('-C')) {
                                return 'blue-cell'
                            } else if (cellData && cellData.endsWith('-S')) {
                                return 'green-cell'
                            } else if (cellData && cellData.endsWith('-O')) {
                                return 'grey-cell'
                            } else {
                                return ''
                            }
                        } else {
                            return ''
                        }
                    },
                    headerClassName: 'custom-header' // Apply custom-header class to color column headers // Apply the custom-header class to color the headers grey
                }))} 
                slots={{ 
                    toolbar: GridToolbar, 
                }}
                rowClassName={(params) => getRowCellBackgroundColor(params)}
            />
        </Paper>
    )
}

export default StatusTable