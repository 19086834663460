export default function parseProgramStats(data) {
    let programs = []
    let programNames = []
    let programStatistics = []
    if (data.Programs.length === 0) {
        return {
            programs: [],
            programStatistics: []
        }
    }

    for (const program of data.Programs) {
        const programName = program.Program
        const programSpindleTime = program.Spindle_Run_Time
        const date = new Date(program.Timestamp['$date'])

        const options = {
            weekday: 'short', // Short weekday name (e.g., Sun)
            month: 'short',   // Short month name (e.g., Aug)
            day: 'numeric',   // Numeric day (e.g., 13)
            year: 'numeric',   // Full year (e.g., 2023)
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true
        }
        const formattedDate = date.toLocaleString('en-US', options)
        if (programNames.includes(programName)) {
            programs.push({
                name: programName,
                spindleTime: programSpindleTime,
                date: formattedDate
            })
            for (let progStatsObj of programStatistics) {
                if (progStatsObj.name === programName) {
                    progStatsObj.totalSpindleTime += programSpindleTime
                    progStatsObj.frequency += 1
                    progStatsObj.averageSpindleTime = progStatsObj.totalSpindleTime / progStatsObj.frequency
                    if (programSpindleTime > progStatsObj.spindleTimeHigh) {
                        progStatsObj.spindleTimeHigh = programSpindleTime
                    }
                    if (programSpindleTime < progStatsObj.spindleTimeLow) {
                        progStatsObj.spindleTimeLow = programSpindleTime
                    }

                }
            }
        } else {
            programNames.push(programName)
            programs.push({
                name: programName,
                spindleTime: programSpindleTime,
                date: formattedDate
            })
            programStatistics.push({
                name: programName,
                totalSpindleTime: programSpindleTime,
                frequency: 1,
                averageSpindleTime: programSpindleTime / 1,
                spindleTimeLow: programSpindleTime,
                spindleTimeHigh: programSpindleTime,
            })
        }
    }
    return {
        programs: programs,
        programStatistics: programStatistics
    }
}