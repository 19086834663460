import { React } from 'react'
import { Typography, Dialog, DialogTitle, DialogContent, Stack, IconButton, List, ListItem, ListItemIcon, ListItemText } from "@mui/material/"
import CloseIcon from '@mui/icons-material/Close'

export default function RegistrationErrorDialog({ registrationFormValues, setRegistrationFormValues }) {
    
    const closeRegistrationErrorDialog = () => {
        setRegistrationFormValues((prev) => ({...prev, registrationErrorDialogToggle: false }))
    }

    return (
        <Dialog
            open={registrationFormValues.registrationErrorDialogToggle}
            onClose={closeRegistrationErrorDialog}
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle sx={{ backgroundColor: '#0e4780', color: 'white' }}>
                Registration errors
                <IconButton
                    size='small'
                    onClick={closeRegistrationErrorDialog}
                    sx={{ float: 'right', color: 'white' }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers sx={{ display: 'flex', justifyContent: 'center', padding: 4 }}>
                <Stack spacing={2}>
                    {/* {submissionResult.result === true ? <CheckIcon fontSize='large' color='success' sx={{ display: 'flex', alignSelf: 'center'}}/> : <ErrorOutlineIcon fontSize='large' color='error' sx={{ display: 'flex', alignSelf: 'center'}}/>} */}
                    <Typography sx={{ fontSize: 24, textAlign: 'center' }}>
                        You must complete these fields <span style={{ textDecoration: 'underline' }}>before</span> you can register as a visitor:
                    </Typography>
                    <List>
                        {registrationFormValues.registrationErrorArray.map((error) => (
                            <ListItem key={error} disablePadding>
                                <ListItemIcon>
                                    <CloseIcon fontSize='large' color='error'/>
                                </ListItemIcon>
                                <ListItemText primaryTypographyProps={{ sx: { fontSize: '18px' } }} primary={error}/>
                            </ListItem>
                        ))}
                    </List>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}