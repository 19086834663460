import React from 'react'
import { Grid, Typography } from "@mui/material/"

export default function Unauthorized() {


    return (
        <Grid container sx={{ backgroundColor: '#002233', height: '75vh'}}>
            <Grid item xs={1} sm={2}></Grid>
            <Grid item xs={10} sm={8} sx={{  color: 'white', paddingTop: 10 }}>
                <Grid container>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', paddingBottom: 5}}>
                        <Typography variant="h2">403 - Forbidden</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', paddingBottom: 5}}>
                        <Typography variant="h4">You don't have permission to access this resource</Typography>
                    </Grid>

                </Grid>
            </Grid>
            <Grid item xs={1} sm={2}></Grid>
        </Grid>
    )
}