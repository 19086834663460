import { React } from 'react'
import { Dialog, DialogTitle, DialogContent, IconButton, Button, Box } from "@mui/material/" 
import CloseIcon from '@mui/icons-material/Close'
import VisitorFOD from './VisitorFOD.pdf'

export default function VisitorFODDialog({ fodDialogToggle, setRegistrationFormValues }) {
    
    const closeFodDialog = () => {
        setRegistrationFormValues((prev) => ({...prev, fodDialogToggle: false}))
    }

    return (
        <Dialog
            open={fodDialogToggle}
            onClose={closeFodDialog}
            fullWidth
            maxWidth="lg"
        >
            <DialogTitle sx={{ backgroundColor: '#0e4780', color: 'white' }}>
                R&S Foregin Object Debris Document
                <IconButton
                    aria-label="close"
                    onClick={closeFodDialog}
                    sx={{ position: 'absolute', right: 8, top: 8, color: 'white' }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <iframe
                    src={VisitorFOD}
                    width="100%"
                    height="600px"
                    style={{ border: 'none' }}
                    title="VisitorFOD"
                />
                <Box
                    sx={{
                        paddingTop: 2,
                        display: 'flex',
                        justifyContent: 'right' 
                    }}
                >
                    <Button
                        sx={{
                            backgroundColor: '#0e4780',
                            color: 'white',
                            textTransform: 'none',
                            fontSize: 16,
                            padding: 1.75,
                            '&:hover': { cursor: 'pointer', backgroundColor: '#0B3866' }
                        }} 
                        onClick={() => {
                            setRegistrationFormValues((prev) => ({...prev, fodAcceptance: 1}))
                            closeFodDialog()
                        }}
                    >
                        Accept FOD
                    </Button>
                    <Button
                        sx={{
                            backgroundColor: '#5d6c7c',
                            marginLeft: 1,
                            color: 'white',
                            textTransform: 'none',
                            fontSize: 16,
                            padding: 1.75,
                            '&:hover': { cursor: 'pointer', backgroundColor: '#4f5d6a' }
                        }} 
                        onClick={() => {
                            setRegistrationFormValues((prev) => ({...prev, fodAcceptance: 0}))
                            closeFodDialog()
                        }}
                    >
                        Deny FOD
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}