export default function parseDailyRecordStats(data) {

    let dailyRecords = []
    let totalRecords = 0;

    if (data.DailyRecords.length === 0) {
        return {
            totalRecords: totalRecords,
            statistics: [],
            dailyRecords: dailyRecords
        }
    }
    let totalCuttingTime = 0;
    let cuttingTimeHigh = data.DailyRecords[0].Cutting_Time
    let cuttingTimeLow = data.DailyRecords[0].Cutting_Time //max time in a day

    let totalOperatingTime = 0;
    let operatingTimeHigh = data.DailyRecords[0].Operating_Time
    let operatingTimeLow = data.DailyRecords[0].Operating_Time //max time in a day

    let totalRunningTime = 0;
    let runningTimeHigh = data.DailyRecords[0].Running_Time
    let runningTimeLow = data.DailyRecords[0].Running_Time //max time in a day

    let totalSpindleRunTime = 0;
    let spindleTimeHigh = data.DailyRecords[0].Spindle_Time
    let spindleTimeLow = data.DailyRecords[0].Spindle_Time //max time in a day


    for (const record of data.DailyRecords) {
        totalCuttingTime += parseFloat(record.Cutting_Time);
        totalOperatingTime += parseFloat(record.Operating_Time);
        totalRunningTime += parseFloat(record.Running_Time);
        totalSpindleRunTime += parseFloat(record.Spindle_Time);
        let date = new Date(record.Timestamp['$date'])
        const options = {
            weekday: 'short', // Short weekday name (e.g., Sun)
            month: 'short',   // Short month name (e.g., Aug)
            day: 'numeric',   // Numeric day (e.g., 13)
            year: 'numeric'   // Full year (e.g., 2023)
        }
        const formattedDate = date.toLocaleString('en-US', options);
        if (record.Cutting_Time > cuttingTimeHigh) {
            cuttingTimeHigh = record.Cutting_Time
        }
        if (record.Cutting_Time < cuttingTimeLow) {
            cuttingTimeLow = record.Cutting_Time
        }
        if (record.Operating_Time > operatingTimeHigh) {
            operatingTimeHigh = record.Operating_Time
        }
        if (record.Operating_Time < operatingTimeLow) {
            operatingTimeLow = record.Operating_Time
        }
        if (record.Running_Time > runningTimeHigh) {
            runningTimeHigh = record.Running_Time
        }
        if (record.Running_Time < runningTimeLow) {
            runningTimeLow = record.Running_Time
        }
        if (record.Spindle_Time > spindleTimeHigh) {
            spindleTimeHigh = record.Spindle_Time
        }
        if (record.Spindle_Time < spindleTimeLow) {
            spindleTimeLow = record.Spindle_Time
        }
        dailyRecords.push({
            cuttingTime: record.Cutting_Time,
            operatingTime: record.Operating_Time,
            spindleTime: record.Spindle_Time,
            runningTime: record.Running_Time,
            date: formattedDate
        })
        totalRecords++;
    }

    return {
        totalRecords: totalRecords,
        statistics: [
            {
                type: "Cutting Time",
                average: totalCuttingTime / totalRecords,
                high: cuttingTimeHigh,
                low: cuttingTimeLow,
                totalRecords: totalRecords //Adding total inside just to make it easy to post into table
            },
            {
                type: "Operating Time",
                average: totalOperatingTime / totalRecords,
                high: operatingTimeHigh,
                low: operatingTimeLow,
                totalRecords: totalRecords //Adding total inside just to make it easy to post into table
            },
            {
                type: "Running Time",
                average: totalRunningTime / totalRecords,
                high: runningTimeHigh,
                low: runningTimeLow,
                totalRecords: totalRecords //Adding total inside just to make it easy to post into table
            },
            {
                type: "Spindle Time",
                average: totalSpindleRunTime / totalRecords,
                high: spindleTimeHigh,
                low: spindleTimeLow,
                totalRecords: totalRecords //Adding total inside just to make it easy to post into table
            }
        ],
        dailyRecords: dailyRecords
    }
}