import * as React from 'react';
import { Typography, FormHelperText } from '@mui/material/'
import { CartesianGrid, XAxis, YAxis, Tooltip, Bar, BarChart } from 'recharts'

const GroupDepartProgBarGraph = ({programStats}) => {
    var topFivePrograms = programStats.programs.slice(0,5)
    const topTwentyProgramCount = topFivePrograms.reduce((acc, currentObj) => acc + currentObj.Frequency, 0)
    if (topFivePrograms === 0) {
        topFivePrograms =  [{
            "Program": "No",
            "Frequency": 0
        },{
            "Program": "Programs",
            "Frequency": 0
        },{
            "Program": "Available",
            "Frequency": 0
        }]
    }

    return (
        <React.Fragment>
            <Typography variant='h5' style={{ fontFamily: 'monospace' }} gutterBottom sx={{ textAlign: 'left', paddingTop: 1 }}>Top 5 Programs</Typography>
            <Typography variant='h7' style={{ fontFamily: 'monospace' }} gutterBottom sx={{ textAlign: 'left' }}>Total Records: {topTwentyProgramCount}. Ordered greatest to least.</Typography>
            <BarChart width={1000} height={350} data={topFivePrograms}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="Program"/>
                <YAxis label={{ value: 'Frequency', angle: -90, position: 'insideLeft' }}/>
                <Tooltip />
                <Bar dataKey="Frequency" fill="#0e4780" />
                {/* <Bar dataKey="Frequency" fill="#7a5195" /> */}
                {/* <Bar dataKey="Frequency" fill="#ffa600" /> */}
            </BarChart>
            <FormHelperText sx={{textAlign: 'Left'}}>Frequency = Number of times ran.</FormHelperText>
        </React.Fragment>
    )
}

export default GroupDepartProgBarGraph