import * as React from 'react';
import { Box, Collapse, IconButton, Table, TableBody, TableContainer, TableHead, TableRow, Typography, Paper, FormHelperText } from '@mui/material/'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { secondsToHms, StyledTableCell, StyledTableRow } from '../utils'

function Row(props) {
    const { row } = props
    const [open, setOpen] = React.useState(false)

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' }}}>
                <StyledTableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                    {row.program}
                </StyledTableCell>
                <StyledTableCell align="left">{secondsToHms(row.totalSpindleTime)}</StyledTableCell>
                <StyledTableCell align="left">{secondsToHms(row.spindleTimeHigh)}</StyledTableCell>
                <StyledTableCell align="left">{secondsToHms(row.spindleTimeLow)}</StyledTableCell>
                <StyledTableCell align="left">{secondsToHms(row.average)}</StyledTableCell>
                <StyledTableCell align="right">{row.frequency}</StyledTableCell>
            </TableRow>
            <TableRow>
                <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 1 }}>
                        <Typography variant="h6" style={{ fontFamily: 'monospace' }} gutterBottom component="div">
                            History
                        </Typography>
                        <Table size="small" aria-label="purchases">
                            <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>Date</StyledTableCell>
                                <StyledTableCell align="left">Program</StyledTableCell>
                                <StyledTableCell align="left">Spindle Time</StyledTableCell>
                            </StyledTableRow>
                            </TableHead>
                            <TableBody>
                            {row.history.map((historyRow) => (
                                <StyledTableRow key={historyRow.date}>
                                    <StyledTableCell component="th" scope="row">
                                        {historyRow.date}
                                    </StyledTableCell>
                                    <StyledTableCell align="left">{historyRow.program}</StyledTableCell>
                                    <StyledTableCell align="left">{secondsToHms(historyRow.spindleTime)}</StyledTableCell>
                                </StyledTableRow>
                            ))}
                            </TableBody>
                        </Table>
                        </Box>
                    </Collapse>
                </StyledTableCell>
            </TableRow>
        </React.Fragment>
    )
}

export default function ProgramTbl({machineStatistics, machineName}) {
    let rows = []
    let keyValue = 0

    for (const program of machineStatistics.programStatistics) {
        let history = []
        keyValue++
        for (const programHistory of machineStatistics.programs) {
            if (programHistory.name === program.name) {
                history.push({
                    program: programHistory.name,
                    spindleTime: programHistory.spindleTime,
                    date: programHistory.date
                })
            }
        }
        rows.push({
            program: program.name, 
            totalSpindleTime: program.totalSpindleTime, 
            spindleTimeHigh: program.spindleTimeHigh, 
            spindleTimeLow: program.spindleTimeLow, 
            average: program.averageSpindleTime,
            frequency: program.frequency,
            keyValue: keyValue,
            history: history
        })
    }

    if (rows.length === 0) {
        rows.push({
            program: "No information available", 
            totalSpindleTime: "", 
            spindleTimeHigh: "", 
            spindleTimeLow: "", 
            average: "",
            frequency: "",
            keyValue: "",
            history: []
        })

        return (
            <React.Fragment>
                <Typography variant='h5' style={{ fontFamily: 'monospace' }} gutterBottom sx={{ textAlign: 'left' }}>Program Table</Typography>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>{machineName}</StyledTableCell>
                                <StyledTableCell>Program</StyledTableCell>
                                <StyledTableCell align="left">Total ST</StyledTableCell>
                                <StyledTableCell align="left">High</StyledTableCell>
                                <StyledTableCell align="left">Low</StyledTableCell>
                                <StyledTableCell align="left">Average</StyledTableCell>
                                <StyledTableCell align="right">Frequency</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    No information available
                                </StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <FormHelperText style={{ textAlign: 'right' }}>Time format: HH:MM:SS</FormHelperText>
            </React.Fragment>
        )
    } else {
        return (
            <React.Fragment>
                <Typography variant='h5' style={{ fontFamily: 'monospace' }} gutterBottom sx={{ textAlign: 'left' }}>Program Table</Typography>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>{machineName}</StyledTableCell>
                                <StyledTableCell>Program</StyledTableCell>
                                <StyledTableCell align="left">Total ST</StyledTableCell>
                                <StyledTableCell align="left">High</StyledTableCell>
                                <StyledTableCell align="left">Low</StyledTableCell>
                                <StyledTableCell align="left">Average</StyledTableCell>
                                <StyledTableCell align="right">Frequency</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                        {rows.map((row) => (
                            <Row key={row.keyValue} row={row}/>
                        ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <FormHelperText style={{ textAlign: 'right' }}>ST: Spindle Time. Time format: HH:MM:SS</FormHelperText>
            </React.Fragment>
        )
    }
}