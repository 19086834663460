export default function parseErrorStats(data) {
    let errors = []
    let errorCodes = []
    let errorCodeFrequency = []
    if (data.Errors.length === 0) {
        return {
            errors: errors,
            errorCodeFrequency: errorCodeFrequency
        }
    }

    for (const record of data.Errors) {
        const errorCode = record.Code
        const errordescription = record.Description

        const errorText = record.Text
        const errorTextTrimmed = errorText.replace(/\s+/g, ' ').trim() //Remove extra whitespace
        const errorTextArray = errorTextTrimmed.split(";") //Seperate by ;
        const errorLongdescription = errorTextArray.length >= 2 ? errorTextArray[1].toString().substring(36) : '' //Remove first 36 characters. This is error code, alarm, and date
        let errorAlarmValue = errorTextArray[0].split(" ")[1] //This will grab alarm value

        const date = new Date(record.Timestamp['$date'])

        const options = {
            weekday: 'short', // Short weekday name (e.g., Sun)
            month: 'short',   // Short month name (e.g., Aug)
            day: 'numeric',   // Numeric day (e.g., 13)
            year: 'numeric',   // Full year (e.g., 2023)
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true
        }
        
        const formattedDate = date.toLocaleString('en-US', options)
        if (errorCodes.includes(errorCode)) { 
            errors.push({
                code: errorCode,
                alarm: errorAlarmValue,
                description: errordescription,
                text: errorLongdescription,
                date: formattedDate
            })
            for (let obj of errorCodeFrequency) {
                if (obj.code === errorCode) {
                    obj.frequency = obj.frequency + 1
                }
            }
        } else {
            errorCodes.push(errorCode)
            errors.push({
                code: errorCode,
                alarm: errorAlarmValue,
                description: errordescription,
                text: errorLongdescription,
                date: formattedDate
            })
            errorCodeFrequency.push({
                code: errorCode,
                alarm: errorAlarmValue,
                description: errordescription,
                text: errorText,
                frequency: 1
            })
        }
    }
    
    return {
        errors: errors,
        errorCodeFrequency: errorCodeFrequency
    }
}