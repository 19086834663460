import { Grid, Typography, Card, CardContent, CardMedia, Button } from '@mui/material/'
import Feature from './Feature.js'
import MM50 from '../images/mm50.png'
import MR80 from '../images/mr80.png'
import FAI60 from '../images/fai60.png'
import P35 from '../images/portal35.png'
import VM50 from '../images/vm50.png'

const FeatureList = () => {
    const features = [{
            index: 0,
            title: "Real Time Machine Data",
            description: "Connecting to machines utilizing MTConnect and CncNetPDM, Apollo delivers real-time machine data to your fingertips This allows you to monitor performance, " +
            "identify issues quickly, and make data-driven decisions that improve efficiency and reduce downtime.",
            image: MM50,
            button: false,
            buttonTitle: null,
            buttonRedirect: null,
            discriptionSX: 1,
            discriptionSM: 1,
            discriptionMD: 1,
            imageSX: 2,
            imageSM: 2,
            imageMD: 2
        },{
            index: 1,
            title: "Automated Machine Reports",
            description: `Interested in the current utilization of a machine or department? How about the most common errors or the most frequently run programs over a given time period? 
            Apollo's automated machine reports generate a printable PDF that you can take with you on the go. Features include diverse report types, 
            customizable timeframes, and dynamic data visualization.Interested in the current utilization of a machine or department? 
            How about the most common errors or the most frequently run programs over a given time period? Apollo's automated machine reports generate a printable PDF that you can take 
            with you on the go. Features include diverse report types, customizable timeframes, and dynamic data visualization.`,
            image: MR80,
            button: true,
            buttonTitle: 'Machine Reports',
            buttonRedirect: '/reportGeneration',
            discriptionSX: 4,
            discriptionSM: 4,
            discriptionMD: 3,
            imageSX: 3,
            imageSM: 3,
            imageMD: 4
        },{
            index: 2,
            title: "Automatic Fai Conversion",
            description: `The FAI Converter automates the conversion of Revision B and Revision C FAI's to the latest fair template version accepted by Net-Inspect. 
            This tool makes conversions that used to take hours last only a moment.`,
            image: FAI60,
            button: true,
            buttonTitle: 'FAI Conversion',
            buttonRedirect: '/faiConverter',
            discriptionSX: 5,
            discriptionSM: 5,
            discriptionMD: 5,
            imageSX: 6,
            imageSM: 6,
            imageMD: 6
        },{
            index: 3,
            title: "Gateway To The Shop Floor",
            description: `Transparency isn't just important to a manager, it's also important to the customer. The Shop Portal is an inside look to the status of your purchase order, 
            part and job number. Interested in what we have in inventory? The Shop Portal is the place to go. With many more features in development, This is an area to watch.`,
            image: P35,
            button: true,
            buttonTitle: 'Shop Portal',
            buttonRedirect: '/portal',
            discriptionSX: 8,
            discriptionSM: 8,
            discriptionMD: 7,
            imageSX: 7,
            imageSM: 7,
            imageMD: 8
        },{
            index: 4,
            title: "Managing Your Visitors",
            description: `Apollo's built in Visitor Management feature allows you to track your visitors, their contact information, how long they where here, and who they came to see. 
            Want your managers to be notified when someone is here to see them? The Visitor Management software handles that too!`,
            image: VM50,
            button: true,
            buttonTitle: 'Visitor Management',
            buttonRedirect: '/VisitorSignInMenu',
            discriptionSX: 9,
            discriptionSM: 9,
            discriptionMD: 9,
            imageSX: 10,
            imageSM: 10,
            imageMD: 10
        }
    ]
    return (
        <Grid container
            sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                maxWidth: '1920px',
                marginInline: 'auto',
                textAlign: 'center',
                paddingTop: 4,
                paddingBottom: 4
            }}
        >
            {features.map((feature) => (<Feature key={feature.index} feature={feature} />))}
        </Grid>
    )
}

export default FeatureList