import { React } from 'react'
import { Link } from 'react-router-dom'
import { Box, Grid, Typography, Card, CardActionArea, CardMedia, CardContent } from "@mui/material/" 
import CreateAccountImage from './images/createAccount.png'
import SignInImage from './images/signIn.png'
import SignOutImage from './images/signOut.png'

export default function VisitorSignInMenu ({ client }) {

    return (
        <Box sx={{ backgroundColor: '#002233' }}>
            <Grid container sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginInline: 'auto', maxWidth: '1920px', height: 'calc(100vh - 63.59px)' }}>
                <Grid item sm={12} md={4} lg={4} display='flex' justifyContent='center' alignItems='center' padding={2}>
                    <Card sx={{ maxWidth: 345, borderRadius: 3, backgroundColor: '#213A72' }}>
                        <CardActionArea
                            component={Link}
                            to={'/VisitorSignInMenu/CreateAccount'}
                        >
                            <CardMedia
                                component="img"
                                height="200"
                                image={CreateAccountImage}
                                alt="create account"
                            />
                            <CardContent sx={{ background: '#213A72' }}>
                                <Typography gutterBottom variant="h5" component="div" color="white">
                                    Create an account
                                </Typography>
                                <Typography variant="body2" color="white">
                                    Never created an account before? This is a great place to start. Click here
                                    to register!
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item sm={12} md={4} display='flex' justifyContent='center' alignItems='center' sx={{ paddingLeft: 2, paddingRight: 2 }}>
                    <Card sx={{ maxWidth: 345, borderRadius: 3, backgroundColor: '#213A72' }}>
                        <CardActionArea
                            component={Link}
                            to={'/VisitorSignInMenu/SignIn'}
                        >
                            <CardMedia
                                component="img"
                                height="200"
                                image={SignInImage}
                                alt="Sign in"
                            />
                            <CardContent sx={{ background: '#213A72' }}>
                                <Typography gutterBottom variant="h5" component="div" color="white">
                                    Sign in
                                </Typography>
                                <Typography variant="body2" color="white">
                                    Back for another visit at R&S Machining? Click here to sign in!
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
                <Grid item sm={12} md={4} display='flex' justifyContent='center' alignItems='center' padding={2}>
                    <Card sx={{ maxWidth: 345, borderRadius: 3, backgroundColor: '#213A72' }}>
                        <CardActionArea
                            component={Link}
                            to={'/VisitorSignInMenu/SignOut'}
                        >
                            <CardMedia
                                component="img"
                                height="200"
                                image={SignOutImage}
                                alt="Sign out"
                            />
                            <CardContent sx={{ background: '#213A72' }}>
                                <Typography gutterBottom variant="h5" component="div" color="white">
                                    Sign out
                                </Typography>
                                <Typography variant="body2" color="white">
                                    On your way out the door? Don't forget to sign out before leaving!
                                </Typography>
                            </CardContent>
                        </CardActionArea>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    )
}