import React from 'react'
import { Grid, Typography } from "@mui/material/"

export default function BugReport() {


    return (
        <Grid container sx={{ backgroundColor: '#002233', height: '75vh'}}>
            <Grid item xs={1} sm={2}></Grid>
            <Grid item xs={10} sm={8} sx={{  color: 'white', paddingTop: 10 }}>
                <Grid container>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', paddingBottom: 5}}>
                        <Typography variant="h2">Found a bug?</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center', paddingBottom: 3}}>
                        <Typography variant="h5">Please email: bloyd@randsmachine.com</Typography>
                    </Grid>
                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center'}}>
                        <Typography variant="h5">with a detailed explanation of the bug and how it occured.</Typography>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={1} sm={2}></Grid>
        </Grid>
    )
}