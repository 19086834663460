import * as React from 'react';
import { Typography, FormHelperText } from '@mui/material/'
import { CartesianGrid, XAxis, YAxis, Tooltip, Bar, BarChart, Legend } from 'recharts'

const GroupDailyRecordHLAChart = ({groupStatistics}) => {

    if (groupStatistics.groupStats.length === 0) {
        groupStatistics.groupStats =  [{
            "High": 0,
            "Low": 0,
            "Average": 0.00,
            "TotalRecords": 0,
            "Type": 'Cutting Time'
        },{
            "High": 0,
            "Low": 0,
            "Average": 0.00,
            "TotalRecords": 0,
            "Type": 'Spindle Time'
        },{
            "High": 0,
            "Low": 0,
            "Average": 0.00,
            "TotalRecords": 0,
            "Type": 'Operating Time'
        },{
            "High": 0,
            "Low": 0,
            "Average": 0.00,
            "TotalRecords": 0,
            "Type": 'Running Time'
        }]
    }

    return (
        <React.Fragment>
            <Typography variant='h5' style={{ fontFamily: 'monospace' }} gutterBottom sx={{ textAlign: 'left', paddingTop: 3 }}>Daily Record High, Low, Average Graph</Typography>
            <Typography variant='h7' style={{ fontFamily: 'monospace' }} gutterBottom sx={{ textAlign: 'left' }}>Total Records: {groupStatistics.totalRecords}. Time measured in seconds.</Typography>
            <BarChart width={500} height={250} data={groupStatistics.groupStats}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="Type" />
                <YAxis label={{ value: 'Seconds', angle: -90, position: 'insideLeft', offset: 0 }}/>
                <Tooltip />
                <Legend />
                <Bar dataKey="High" fill="#0e4780" />
                <Bar dataKey="Low" fill="#7a5195" />
                <Bar dataKey="Average" fill="#ffa600" />
            </BarChart>
            <FormHelperText sx={{textAlign: 'Left'}}>HLA = High, Low, and Average</FormHelperText>
        </React.Fragment>
    )
}

export default GroupDailyRecordHLAChart