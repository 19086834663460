import * as React from 'react'
import AdministratorAppbar from './AppbarVersions/AdministratorAppbar'
import EmployeeAppbar from './AppbarVersions/EmployeeAppbar'
import CustomerAppbar from './AppbarVersions/CustomerAppbar'
import NoRoleAppbar from './AppbarVersions/NoRoleAppbar'
import VisitorSignInAppbar from './AppbarVersions/VisitorSignInAppbar'


export default function Navbar({ client }) {
  if (client.realmAccess['roles'].includes("Administrator")) {
    return <AdministratorAppbar client={client} />
  } else if (client.realmAccess['roles'].includes("Employee")) {
    return <EmployeeAppbar client={client} />
  } else if (client.realmAccess['roles'].includes("Customer")) {
    return <CustomerAppbar client={client} />
  } else if (client.realmAccess['roles'].includes("VisitorSignIn")) {
    return <VisitorSignInAppbar client={client} />
  } else {
    return <NoRoleAppbar client={client} />
  }
}