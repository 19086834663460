import { React } from 'react'
import { Dialog, DialogTitle, DialogContent, IconButton, Button, Box } from "@mui/material/" 
import CloseIcon from '@mui/icons-material/Close'
import VisitorNDA from './VisitorNDA.pdf'

export default function VisitorNDADialog({ ndaDialogToggle, setRegistrationFormValues }) {
    
    const closeNdaDialog = () => {
        setRegistrationFormValues((prev) => ({...prev, ndaDialogToggle: false }))
    }

    return (
        <Dialog
            open={ndaDialogToggle}
            onClose={closeNdaDialog}
            fullWidth
            maxWidth="lg"
        >
            <DialogTitle sx={{ backgroundColor: '#0e4780', color: 'white' }}>
                R&S Non-Disclosure Agreement Document
                <IconButton
                    onClick={closeNdaDialog}
                    sx={{ float: 'right', color: 'white' }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers>
                <iframe
                    src={VisitorNDA}
                    width="100%"
                    height="600px"
                    style={{ border: 'none' }}
                    title="VisitorNDA"
                />
                <Box
                    sx={{
                        paddingTop: 2,
                        display: 'flex',
                        justifyContent: 'right' 
                    }}
                >
                    <Button
                        sx={{
                            backgroundColor: '#0e4780',
                            color: 'white', 
                            textTransform: 'none', 
                            fontSize: 16, 
                            padding: 1.75,
                            '&:hover': { cursor: 'pointer', backgroundColor: '#0B3866' }
                        }} 
                        onClick={() => {
                            setRegistrationFormValues((prev) => ({...prev, ndaAcceptance: 1}))
                            closeNdaDialog()
                        }}
                    >
                        Accept NDA
                    </Button>
                    <Button 
                        sx={{
                            backgroundColor: '#5d6c7c',
                            marginLeft: 1,
                            color: 'white', 
                            textTransform: 'none', 
                            fontSize: 16, 
                            padding: 1.75,
                            '&:hover': { cursor: 'pointer', backgroundColor: '#4f5d6a' }
                        }} 
                        onClick={() => {
                            setRegistrationFormValues((prev) => ({...prev, ndaAcceptance: 0}))
                            closeNdaDialog()
                        }}
                    >
                        Deny NDA
                    </Button>
                </Box>
            </DialogContent>
        </Dialog>
    )
}