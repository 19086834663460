import { React } from 'react'
import { Typography, Dialog, DialogTitle, DialogContent, Stack, IconButton } from "@mui/material/"
import CloseIcon from '@mui/icons-material/Close'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

export default function UnverifiedUserDialog({ client, loginState, setLoginState, initLoginState }) {
    
    const closeUnverifiedUserDialog = () => {
        setLoginState((prev) => (initLoginState))
    }

    return (
        <Dialog
            open={loginState.unverifiedVisitorToggle}
            onClose={closeUnverifiedUserDialog}
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle sx={{ fontSize: 26 }}>
                Visitor not found
                <IconButton
                    onClick={closeUnverifiedUserDialog}
                    sx={{ float: 'right' }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers sx={{ display: 'flex', justifyContent: 'center'}}>
                <Stack spacing={2}>
                    <ErrorOutlineIcon fontSize='large' color='error' sx={{ display: 'flex', alignSelf: 'center'}}/>
                    <Typography sx={{ fontSize: 20, textAlign: 'center' }}>
                        {loginState.message}
                    </Typography>
                    <Typography sx={{ fontSize: 18, textAlign: 'center' }}>
                        Please correct the email or create an account before using this feature.
                    </Typography>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}