import { Grid, Typography } from '@mui/material/'


const Banner = () => {

    return (
        <Grid container 
            sx={{
                maxWidth: '1920px',
                display: 'flex',
                alignItems: 'center',
                marginInline: 'auto',
                backgroundColor: '#002233'
            }}
        >
            <Grid item md={1}/>
            <Grid item md={11} lg={5} sx={{ margin: { xs: 2 } }}>
                <Typography
                    sx={{  
                        color: 'white',
                        fontFamily: 'monospace',
                        typography: { xs: 'h4', sm: 'h3', md: 'h2', lg: 'h1' }
                    }}
                >
                    Less friction,
                </Typography>
                <Typography 
                    sx={{
                        color: 'white',
                        fontFamily: 'monospace', 
                        paddingBottom: 2,
                        typography: { xs: 'h4', sm: 'h3', md: 'h2', lg: 'h2' }
                    }}
                >
                    More manufacturing
                </Typography>
                <Typography 
                    variant="subtitle1" 
                    sx={{
                        color: 'white',
                        fontSize: { xs: 14, sm: 16, md: 18, lg: 20 }
                    }}
                >
                    Apollo Software Solutions provides much-needed insight into the manufacturing experience.
                    From machine monitoring and report generation to a shop portal that lets customers see the status of their orders, Apollo is your gateway to a more transparent manufacturing environment
                </Typography>
            </Grid>
            <Grid item md={12} lg={5} className='apolloLogoBackground' />
            <Grid item md={0} lg={1}/>
        </Grid>
    )
}

export default Banner