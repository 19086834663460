import * as React from 'react';
import { Typography } from '@mui/material/'
import { Legend, PieChart, Pie, Cell } from 'recharts'

export default function AverageOperatingTimePieChart({machineStatistics, machineName}) {
    let COLORS = ['#00802B', '#FFB400']
    let pieChartData = []
    let averageRunningTime = 0

    if (machineStatistics.length === 0) {
        COLORS = ['#FF0000']
        pieChartData.push({
            "name": "No data found",
            "value": 100
        })

    } else {
        machineStatistics.forEach((stats) => {
            if (stats.type === "Operating Time") {
                pieChartData.push({
                    "name": stats.type,
                    "value": parseInt(stats.average.toFixed(2))
                })
            } else if (stats.type === "Running Time") {
                averageRunningTime = stats.average.toFixed(2)
            }
        })
        pieChartData.push({
            "name": "Idle Time",
            "value": averageRunningTime - pieChartData[0].value
        })
    }

    const RADIAN = Math.PI / 180;
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * RADIAN);
        const y = cy + radius * Math.sin(-midAngle * RADIAN);
        return (
            <text x={x} y={y} fill="white" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        );
    };



    return (
        <React.Fragment>
            <Typography variant='h5' style={{ fontFamily: 'monospace' }} gutterBottom sx={{ textAlign: 'left' }}>{machineName} Average Operating Time Pie Chart</Typography>
            <Typography variant='h7' style={{ fontFamily: 'monospace' }} gutterBottom sx={{ textAlign: 'left' }}>Made from the total average operating time and running time from the dates selected.</Typography>
            <PieChart width={400} height={250}>
                <Legend layout="horizontal" verticalAlign="bottom" align="center" />
                <Pie
                    data={pieChartData}
                    cx="50%"
                    cy="50%"
                    labelLine={false}
                    label={renderCustomizedLabel}
                    outerRadius={80}
                    fill="#8884d8"
                    dataKey="value"
                >
                    {pieChartData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                    ))}
                </Pie>
            </PieChart>
        </React.Fragment>
    )
}