import * as React from 'react'
import { useState } from 'react'
import { Typography, Grid, Box, Paper, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material/'
import WorkIcon from '@mui/icons-material/Work'
import ShopIcon from '@mui/icons-material/Shop';
import ConstructionIcon from '@mui/icons-material/Construction';
import DescriptionIcon from '@mui/icons-material/Description';
import POStatusForm from './POStatusForm'
import JobStatusForm from './JobStatusForm'
import PartStatusForm from './PartStatusForm'
import InventoryCheckForm from './InventoryCheckForm'
// import AddIcon from '@mui/icons-material/Add'

const determineFeatureToShow = (portalState, client) => {
    if (portalState.POStatusToggle === true) {
        return <POStatusForm client={client} />
    } else if (portalState.JobStatusToggle === true) {
        return <JobStatusForm client={client} />
    } else if (portalState.PartStatusToggle === true) {
        return <PartStatusForm client={client} />
    } else if (portalState.InventoryToggle === true) {
        return <InventoryCheckForm client={client}/>
    } 
    // else if (portalState.featureFiveToggle === true) {
    //     return null
    // } else if (portalState.featureSixToggle === true) {
    //     return null
    // } else if (portalState.featureSevenToggle === true) {
    //     return null
    // } else if (portalState.featureEightToggle === true) {
    //     return null
    // } else if (portalState.featureNineToggle === true) {
    //     return null
    // }
}

export default function Portal({ client }) {
    const initialState = {
        POStatusToggle: false,
        JobStatusToggle: false,
        PartStatusToggle: false,
        InventoryToggle: false
        // featureFiveToggle: false,
        // featureSixToggle: false,
        // featureSevenToggle: false,
        // featureEightToggle: false,
        // featureNineToggle: false,
    }
    const [portalState, setPortalState] = useState(initialState)
    
    const handleFeatureClick = async (stateKey) => {
        await setPortalState(initialState)
        await setPortalState((prevState) => ({
            ...prevState,
            [stateKey]: !prevState[stateKey],
        }))
    }
    
    const portalFeatures = [
        {name: 'Job Status', state: "JobStatusToggle", icon: <WorkIcon />},
        {name: 'Part Status', state: "PartStatusToggle", icon: <ConstructionIcon />},
        {name: 'PO Status', state: "POStatusToggle", icon: <ShopIcon />},
        {name: 'Inventory Check', state: "InventoryToggle", icon: <DescriptionIcon />}
        // {name: 'Feature 5', state: "featureFiveToggle", icon: <AddIcon />},
        // {name: 'Feature 6', state: "featureSixToggle", icon: <AddIcon />},
        // {name: 'Feature 7', state: "featureSevenToggle", icon: <AddIcon />},
        // {name: 'Feature 8', state: "featureEightToggle", icon: <AddIcon />},
        // {name: 'Feature 9', state: "featureNineToggle", icon: <AddIcon />}
    ]

    return (
        <Box sx={{ margin: 1, minHeight:'calc(100vh - 75px)', display: 'flex' }}>
            <Grid container>
                <Grid item sm={2} sx={{ height: '100%' }}>
                    <Paper elevation={4} sx={{ height: '100%' }}>
                        <Box sx={{ padding: 1, paddingTop: 1.5 }}>
                            <Paper elevation={4} sx={{ backgroundColor: '#0e4780' }}>
                                <Typography gutterBottom variant="h6" sx={{ textAlign: 'center', color:'white' }}>Feature List</Typography>
                            </Paper>
                        </Box>
                        <List>
                            {portalFeatures.map((feature) => (
                                <ListItem key={feature.name} disablePadding>
                                    <ListItemButton
                                        id="listItemButton"
                                        onClick={() => handleFeatureClick(feature.state)}
                                    >
                                        <ListItemIcon>
                                            {feature.icon}
                                        </ListItemIcon>
                                        <ListItemText primary={feature.name} />
                                    </ListItemButton>
                                </ListItem>
                            ))}
                        </List>
                    </Paper>
                </Grid>
                <Grid item sm={10} sx={{ height: '100%' }}>
                    <Paper elevation={4} sx={{ height: '100%', marginLeft: 1, backgroundColor: 'lightgrey' }}>
                        {determineFeatureToShow(portalState, client)}
                    </Paper>
                </Grid>
            </Grid>
        </Box>
        )
    }


