import { React, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Typography, Dialog, DialogTitle, DialogContent, Stack, IconButton } from "@mui/material/"
import CloseIcon from '@mui/icons-material/Close'
import CheckIcon from '@mui/icons-material/Check'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'

export default function SubmissionResult({ submissionResult, setSubmissionResult, closeVisitorRegDialog }) {
    const navigate = useNavigate()
    const closeSubmissionDialog = () => {
        if (submissionResult.result) {
            navigate('/VisitorSignInMenu/SignIn')
        } else {
            setSubmissionResult((prev) => ({...prev, submissionDialogToggle: false }))
        }
    }

        useEffect(() => {
            if (submissionResult.result === true) {
                const timer = setTimeout(() => {
                    navigate('/VisitorSignInMenu/SignIn')
                }, 5000)
        
                return () => {
                    clearTimeout(timer)
                }
            }// eslint-disable-next-line
        },[])

    return (
        <Dialog
            open={submissionResult.submissionDialogToggle}
            onClose={closeSubmissionDialog}
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle sx={{ backgroundColor: '#0e4780', color: 'white' }}>
                Registration result
                <IconButton
                    size='small'
                    onClick={closeSubmissionDialog}
                    sx={{ float: 'right', color: 'white' }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers sx={{ display: 'flex', justifyContent: 'center'}}>
                <Stack spacing={2}>
                    { submissionResult.result === true ? 
                        <CheckIcon fontSize='large' color='success' sx={{ display: 'flex', alignSelf: 'center'}}/> : 
                        <ErrorOutlineIcon fontSize='large' color='error' sx={{ display: 'flex', alignSelf: 'center'}}/>
                    }
                    <Typography sx={{ fontSize: 20, textAlign: 'center' }}>
                        {submissionResult.message}
                    </Typography>
                    { submissionResult.result === true ? 
                        <Typography sx={{ fontSize: 18, textAlign: 'center' }}>Redirecting you to Sign in!</Typography> : 
                        <></>
                    }
                </Stack>
            </DialogContent>
        </Dialog>
    )
}