import React, { useRef, useEffect } from 'react'
import { Typography, Dialog, DialogTitle, DialogContent, Stack, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'

export default function IdleUserDialog({ state, setState }) {
    const redirectTimeoutRef = useRef(null)

    // Function to start the timeout
    const startRedirectTimeout = () => {
        // Clear any existing timeout
        if (redirectTimeoutRef.current) {
            clearTimeout(redirectTimeoutRef.current)
        }

        // Set a new timeout and store the ID in the ref
        redirectTimeoutRef.current = setTimeout(() => {
            window.location.pathname = '/VisitorSignInMenu'
        }, 10000)
    }
    // Function to close the dialog and clear the timeout
    const closeIdleUserDialog = () => {
        clearTimeout(redirectTimeoutRef.current);
        redirectTimeoutRef.current = null;  // Reset the ref to null
        setState((prev) => ({ ...prev, handleIdleUserToggle: false }))
    }

    // Automatically start the timer when the dialog opens
    useEffect(() => {
        if (state.handleIdleUserToggle) {
            startRedirectTimeout()
        }

        // Cleanup function to clear the timeout when the component unmounts or when the dialog closes
        return () => {
            if (redirectTimeoutRef.current) {
                clearTimeout(redirectTimeoutRef.current)
            }
        };
    }, [state.handleIdleUserToggle])

    return (
        <Dialog
            open={state.handleIdleUserToggle}
            onClose={closeIdleUserDialog}
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle sx={{ backgroundColor: '#0e4780', color: 'white' }}>
                Inactive User
                <IconButton
                    size='small'
                    onClick={closeIdleUserDialog}
                    sx={{ float: 'right', color: 'white' }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers sx={{ display: 'flex', justifyContent: 'center' }}>
                <Stack spacing={2}>
                    <Typography sx={{ fontSize: 18, textAlign: 'center' }}>Redirecting back to the main menu...</Typography>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}