import * as React from 'react';
import { Typography, FormHelperText } from '@mui/material/'
import { CartesianGrid, XAxis, YAxis, Tooltip, Bar, BarChart, Legend } from 'recharts'

const DailyRecordHLAChart = ({machineStatistics, machineName}) => {
    if (machineStatistics.length === 0) {
        machineStatistics =  [{
            "High": 0,
            "Low": 0,
            "Average": 0.00,
            "TotalRecords": 0,
            "Type": 'Cutting Time'
        },{
            "High": 0,
            "Low": 0,
            "Average": 0.00,
            "TotalRecords": 0,
            "Type": 'Spindle Time'
        },{
            "High": 0,
            "Low": 0,
            "Average": 0.00,
            "TotalRecords": 0,
            "Type": 'Operating Time'
        },{
            "High": 0,
            "Low": 0,
            "Average": 0.00,
            "TotalRecords": 0,
            "Type": 'Running Time'
        }]
    } else {
        //Reorder machine statistics in order of cut time, spindle time, operating time and running time
        machineStatistics = [machineStatistics[0], machineStatistics[3], machineStatistics[1], machineStatistics[2]] 
        machineStatistics = machineStatistics.map( machine => ({
            "High": machine.high,
            "Low": machine.low,
            "Average": machine.average.toFixed(2),
            "TotalRecords": machine.totalRecords,
            "Type": machine.type,
        }))
    }

    return (
        <React.Fragment>
            <Typography variant='h5' style={{ fontFamily: 'monospace' }} gutterBottom sx={{ textAlign: 'left', paddingTop: '5px' }}>{machineName} Daily Record HLA Graph</Typography>
            <Typography variant='h7' style={{ fontFamily: 'monospace' }} gutterBottom sx={{ textAlign: 'left' }}>Total Records: {machineStatistics[0].TotalRecords}. Time measured in seconds.</Typography>
            <BarChart width={500} height={250} data={machineStatistics}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="Type" />
                <YAxis label={{ value: 'Seconds', angle: -90, position: 'insideLeft', offset: 0 }}/>
                <Tooltip />
                <Legend />
                <Bar dataKey="High" fill="#0e4780" />
                <Bar dataKey="Low" fill="#7a5195" />
                <Bar dataKey="Average" fill="#ffa600" />
            </BarChart>
            <FormHelperText sx={{textAlign: 'Left'}}>HLA = High, Low, and Average</FormHelperText>
        </React.Fragment>
    )
}

export default DailyRecordHLAChart