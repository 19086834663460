import React from 'react'
import { Grid, Container, Box, Paper, Typography } from '@mui/material/'

const DashboardKey = () => {
    const screenSize = window.innerWidth
    if (screenSize < 961) {
        return (
            <Container maxWidth="lg" sx={{ alignItems:'center', marginBottom: 2 }}>
                <Box sx={{ marginTop: 2 }} >
                    <Grid container sx={{ justifyContent:'center' }}>
                        <Grid item xs={9} component={Paper} elevation={15} sx={{ borderRadius: 10 }} square>
                            <Typography variant="h4" align='center' sx={{margin: 2, marginBottom: 1}}>
                                Server Wall Legend
                            </Typography>
                            <Grid container sx={{ justifyContent:'space-evenly', alignItems:'center' }}>
                                <Grid item xs={1.25} sx={{ backgroundColor:'green', height: '75px', margin: 1, border: 1 }}>
                                </Grid>
                                <Grid item xs={5} sx={{ justifyContent:'center' }}>
                                    <Typography variant="h5">
                                        Machine is active
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ justifyContent:'space-evenly', alignItems:'center' }}>
                                <Grid item xs={1.25} sx={{ backgroundColor: 'yellow', height: '75px', margin: 1, border: 1 }}>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant="h5">
                                        Machine is idle
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ justifyContent:'space-evenly', alignItems:'center' }}>
                                <Grid item xs={1.25} sx={{ backgroundColor: 'red', height: '75px', margin: 1, border: 1 }}>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant="h5">
                                        Machine has an alarm
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ justifyContent:'space-evenly', alignItems:'center' }}>
                                <Grid item xs={1.25} sx={{ backgroundColor: 'gray', height: '75px', margin: 1, marginBottom: 2, border: 1 }}>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant="h5">
                                        Machine is not connected
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        )
    } else {
        return (
            <Container maxWidth="lg" sx={{ alignItems: 'center', marginBottom: 2 }}>
                <Box sx={{ marginTop: 5 }} >
                    <Grid container sx={{ justifyContent: 'center' }}>
                        <Grid item xs={12} component={Paper} elevation={15} sx={{borderRadius: 10}} square>
                            <Typography variant="h3" align='center' sx={{margin: 3}}>
                                Server Wall Legend
                            </Typography>
                            <Grid container sx={{ justifyContent: 'space-evenly', alignItems: 'center' }}>
                                <Grid item xs={1.5} sx={{ backgroundColor: 'green', height: '100px', margin: 1, border: 1 }}>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant="h4">
                                        Machine is active
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ justifyContent: 'space-evenly', alignItems: 'center' }}>
                                <Grid item xs={1.5} sx={{ backgroundColor: 'yellow', height: '100px', margin: 1, border: 1 }}>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant="h4">
                                        Machine is idle
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ justifyContent: 'space-evenly', alignItems: 'center' }}>
                                <Grid item xs={1.5} sx={{ backgroundColor: 'red', height: '100px', margin: 1, border: 1 }}>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant="h4">
                                        Machine has an alarm
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container sx={{ justifyContent: 'space-evenly', alignItems: 'center', marginBottom: 3 }}>
                                <Grid item xs={1.5} sx={{ backgroundColor: 'gray', height: '100px', margin: 1, border: 1 }}>
                                </Grid>
                                <Grid item xs={5}>
                                    <Typography variant="h4">
                                        Machine is not connected
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </Container>
        )
    }
}


export default DashboardKey