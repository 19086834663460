
import { useState, useEffect, useRef } from 'react'
import Keycloak from 'keycloak-js'
// eslint-disable-next-line
/* global client */
// eslint-disable-next-line
const client = new Keycloak({
    //To run in aws linux environment change it to this
    url: `https://apollologin.randsmachine.com`,
    //url: `http://192.168.110.164:8080`,
    realm: `Apolloapp`,
    clientId: `Apollo`
})

const useAuth = () => {
    const isRun = useRef(false);
    const [authenticated, setAuthenticated] = useState(false);

    useEffect(() => {
        if (!isRun.current) {
            isRun.current = true

            client.init({
                onLoad: 'login-required',
                checkLoginIframe: false,
                pkceMethod: 'S256'
            }).then((auth) => {
                if (!auth) {
                    window.location.reload()
                } else {
                    setAuthenticated(true)
                }
            });
        }
    }, []);

    return [client, authenticated];
}

export default useAuth