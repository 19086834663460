import * as React from 'react'
import { AppBar, Box, Typography, IconButton, Grid } from '@mui/material/'
import { Link } from 'react-router-dom'
import Logo from '../Logo.png'
import MobileLogo from '../MobileLogo.png'
import LoginIcon from '@mui/icons-material/Login'


export default function VisitorSignInAppbar({ client }) {

    return (
        <AppBar style={{ background: '#0e4780', position: 'sticky', padding: 5 }}>
            <Grid container sx={{ display: 'flex' }}>
                <Grid item>
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center', width: 'fit-content', '&:hover': { cursor: 'pointer', opacity: 0.8 } }}>
                        <img style={{ maxWidth: '80%' }} onClick={() => window.location.replace('/')} src={ Logo } alt="" />
                    </Box>
                    <Box sx={{ display: { xs: 'flex', md: 'none' }, justifyContent: 'center', width: 'fit-content', '&:hover': { cursor: 'pointer', opacity: 0.8 } }}>
                        <img style={{ maxWidth: '70%', height: '60%', '&:hover': { cursor: 'pointer', opacity: 0.8 } }} onClick={() => window.location.replace('/')} src={ MobileLogo } alt="" />
                    </Box>
                </Grid>
                <Grid item sx={{ display: 'flex' }}>
                    <IconButton
                        size="large"
                        sx={{ display: {xs: 'none', md: 'flex'}, width: 'fit-content', '&:hover': { cursor: 'pointer', opacity: 0.8 } }}
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        component={Link}
                        to={'/VisitorSignInMenu'}
                        color="inherit"
                    >
                        <Typography sx={{ paddingRight: 1, display: { xs: 'none', md: 'flex' } }}>Visitor Sign In Menu</Typography>
                        <LoginIcon />
                    </IconButton>
                    <IconButton
                        size="sm"
                        sx={{ display: {xs: 'flex', md: 'none'}, width: 'fit-content', '&:hover': { cursor: 'pointer', opacity: 0.8 } }}
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        component={Link}
                        to={'/VisitorSignInMenu'}
                        color="inherit"
                    >
                        <Typography sx={{ fontSize: 14, flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>Visitor Sign In Menu</Typography>
                    </IconButton>
                </Grid>
            </Grid>
        </AppBar>
    )
}