import React from 'react'
import { Navigate, BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Navbar from './components/Navbar/Navbar'
import Home from './components/Home/Home'
import ThreeMachineDashboard from './components/MachineDashboard/ServerWall/ThreeMachineDashboard'
import Dashboards from './components/MachineDashboard/Dashboards/Dashboards'
import ServerWallLegend from './components/MachineDashboard/ServerWall/ServerWallLegend'
import ReportGeneration from './components/MachineReports/ReportGeneration'
import VisitorSignInMenu from './components/VisitorSignInMenu/VisitorSignInMenu'
import SignInVisitor from './components/VisitorSignInMenu/SignInVisitor/SignIn'
import SignOutVisitor from './components/VisitorSignInMenu/SignOutVisitor/SignOut'
import CreateVisitorAccount from './components/VisitorSignInMenu/VisitorRegistration/CreateAccount'
import BugReport from './components/BugReport'
import Unauthorized from './components/Unauthorized'
import FaiConverter from './components/FAIConversion/FaiConverter'
import Portal from './components/Portal/Portal'
import Footer from './components/Footer/Footer'
import "./App.css"
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import useAuth from './hooks/useAuth'
import ScrollToTop from './hooks/ScrollToTop'

const PrivateRoute = ({ element, requiredRoles, accessRoles }) => {

  const hasAccess = requiredRoles.some(role => accessRoles.includes(role));
  return hasAccess ? element : <Navigate to="/Unauthorized" />;
}

const App = () => {
  const [client, authenticated ] = useAuth()
  let accessRoles = []
  if (authenticated === true) {
    accessRoles = client.realmAccess['roles']
  }
    return (
      authenticated === true ? (
        <div className="app-container">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Router>
              <ScrollToTop/>
              <Navbar client={client}/>
              <Routes>
                <Route 
                  path="/" 
                  element={<Home />} 
                />
                <Route 
                  path="/Unauthorized" 
                  element={<Unauthorized />} 
                />
                <Route
                  path={'/bugReport'}
                  element={<BugReport />}
                />
                <Route 
                  path={'/VisitorSignInMenu'} 
                  element={<PrivateRoute 
                    element={<VisitorSignInMenu client={client} />} 
                    requiredRoles={['Administrator', 'VisitorSignIn']} 
                    accessRoles={accessRoles} 
                  />}
                />
                <Route 
                  path={'/VisitorSignInMenu/CreateAccount'} 
                  element={<PrivateRoute 
                    element={<CreateVisitorAccount client={client} />} 
                    requiredRoles={['Administrator', 'VisitorSignIn']} 
                    accessRoles={accessRoles} 
                  />}
                />
                <Route 
                  path={'/VisitorSignInMenu/SignIn'} 
                  element={<PrivateRoute 
                    element={<SignInVisitor client={client} />} 
                    requiredRoles={['Administrator', 'VisitorSignIn']} 
                    accessRoles={accessRoles} 
                  />}
                />
                <Route 
                  path={'/VisitorSignInMenu/SignOut'} 
                  element={<PrivateRoute 
                    element={<SignOutVisitor client={client} />} 
                    requiredRoles={['Administrator', 'VisitorSignIn']} 
                    accessRoles={accessRoles} 
                  />}
                />
                <Route 
                  path={'/reportGeneration'} 
                  element={<PrivateRoute 
                    element={<ReportGeneration client={client} />} 
                    requiredRoles={['Administrator', 'Employee']} 
                    accessRoles={accessRoles} 
                  />}
                />
                <Route 
                  path={'/faiConverter'} 
                  element={<PrivateRoute 
                    element={<FaiConverter client={client} />} 
                    requiredRoles={['Administrator', 'Employee']} 
                    accessRoles={accessRoles} 
                  />}
                />
                <Route 
                  path="/Machine-Dashboard" 
                  element={<PrivateRoute 
                    element={<Dashboards client={client} />} 
                    requiredRoles={['Administrator', 'Employee', 'Customer']} 
                    accessRoles={accessRoles} 
                  />} 
                />
                <Route 
                  path={'/Five-Axis-Dashboard'} 
                  element={<PrivateRoute 
                    element={<Dashboards client={client} />} 
                    requiredRoles={['Administrator', 'Employee', 'Customer']} 
                    accessRoles={accessRoles} 
                  />}
                />
                <Route 
                  path={'/Three-Axis-Dashboard'} 
                  element={<PrivateRoute 
                    element={<Dashboards client={client} />} 
                    requiredRoles={['Administrator', 'Employee', 'Customer']} 
                    accessRoles={accessRoles} 
                  />} 
                />
                <Route 
                  path={'/Lathe-Dashboard'} 
                  element={<PrivateRoute 
                    element={<Dashboards client={client} />} 
                    requiredRoles={['Administrator', 'Employee', 'Customer']} 
                    accessRoles={accessRoles} 
                  />}
                />
                <Route 
                  path={'/Horizontal-Dashboard'} 
                  element={<PrivateRoute 
                    element={<Dashboards client={client} />} 
                    requiredRoles={['Administrator', 'Employee', 'Customer']} 
                    accessRoles={accessRoles} 
                  />}
                />
                <Route 
                  path={'/Semiconductor-Dashboard'} 
                  element={<PrivateRoute 
                    element={<Dashboards client={client} />} 
                    requiredRoles={['Administrator', 'Employee', 'Customer']} 
                    accessRoles={accessRoles} 
                  />}
                />
                <Route 
                  path={'/Building-Two-Dashboard'} 
                  element={<PrivateRoute 
                    element={<Dashboards client={client} />} 
                    requiredRoles={['Administrator', 'Employee', 'Customer']} 
                    accessRoles={accessRoles} 
                  />}
                />
                <Route 
                  path={'/Building-Three-Dashboard'} 
                  element={<PrivateRoute 
                    element={<Dashboards client={client} />} 
                    requiredRoles={['Administrator', 'Employee', 'Customer']} 
                    accessRoles={accessRoles} 
                  />}
                />
                <Route 
                  path={'/pageOne'} 
                  element={<PrivateRoute 
                    element={<ThreeMachineDashboard client={client} />} 
                    requiredRoles={['Administrator', 'Employee', 'Customer']} 
                    accessRoles={accessRoles} 
                  />}
                />
                <Route 
                  path={'/pageTwo'} 
                  element={<PrivateRoute 
                    element={<ThreeMachineDashboard client={client} />} 
                    requiredRoles={['Administrator', 'Employee', 'Customer']} 
                    accessRoles={accessRoles} 
                  />}
                />
                <Route 
                  path={'/pageThree'} 
                  element={<PrivateRoute 
                    element={<ThreeMachineDashboard client={client} />} 
                    requiredRoles={['Administrator', 'Employee', 'Customer']} 
                    accessRoles={accessRoles} 
                  />} 
                />
                <Route 
                  path={'/pageFour'} 
                  element={<PrivateRoute 
                    element={<ThreeMachineDashboard client={client} />} 
                    requiredRoles={['Administrator', 'Employee', 'Customer']} 
                    accessRoles={accessRoles} 
                  />}
                />
                <Route 
                  path={'/pageFive'} 
                  element={<PrivateRoute 
                    element={<ThreeMachineDashboard client={client} />} 
                    requiredRoles={['Administrator', 'Employee', 'Customer']} 
                    accessRoles={accessRoles} 
                  />}
                />
                <Route 
                  path={'/pageSix'} 
                  element={<PrivateRoute 
                    element={<ThreeMachineDashboard client={client} />} 
                    requiredRoles={['Administrator', 'Employee', 'Customer']} 
                    accessRoles={accessRoles} 
                  />}
                />
                <Route 
                  path={'/pageSeven'} 
                  element={<PrivateRoute 
                    element={<ThreeMachineDashboard client={client} />} 
                    requiredRoles={['Administrator', 'Employee', 'Customer']} 
                    accessRoles={accessRoles} 
                  />}
                />
                <Route 
                  path={'/pageEight'} 
                  element={<PrivateRoute 
                    element={<ThreeMachineDashboard client={client} />} 
                    requiredRoles={['Administrator', 'Employee', 'Customer']} 
                    accessRoles={accessRoles} 
                  />}
                />
                <Route 
                  path={'/pageNine'} 
                  element={<PrivateRoute 
                    element={<ThreeMachineDashboard client={client} />} 
                    requiredRoles={['Administrator', 'Employee', 'Customer']} 
                    accessRoles={accessRoles} 
                  />}
                />
                  <Route 
                  path={'/pageTen'} 
                  element={<PrivateRoute 
                    element={<ThreeMachineDashboard client={client} />} 
                    requiredRoles={['Administrator', 'Employee', 'Customer']} 
                    accessRoles={accessRoles} 
                  />}
                />
                <Route 
                  path={'/pageEleven'} 
                  element={<PrivateRoute 
                    element={<ThreeMachineDashboard client={client} />} 
                    requiredRoles={['Administrator', 'Employee', 'Customer']} 
                    accessRoles={accessRoles} 
                  />}
                />
                <Route 
                  path={'/pageTwelve'} 
                  element={<PrivateRoute 
                    element={<ThreeMachineDashboard client={client} />} 
                    requiredRoles={['Administrator', 'Employee', 'Customer']} 
                    accessRoles={accessRoles} 
                  />}
                />
                <Route 
                  path={'/pageThirteen'} 
                  element={<PrivateRoute 
                    element={<ThreeMachineDashboard client={client} />} 
                    requiredRoles={['Administrator', 'Employee', 'Customer']} 
                    accessRoles={accessRoles} 
                  />}
                />
                <Route 
                  path={'/pageFourteen'} 
                  element={<PrivateRoute 
                    element={<ThreeMachineDashboard client={client} />} 
                    requiredRoles={['Administrator', 'Employee', 'Customer']} 
                    accessRoles={accessRoles} 
                  />}
                />
                <Route 
                  path={'/pageFifteen'} 
                  element={<PrivateRoute 
                    element={<ThreeMachineDashboard client={client} />} 
                    requiredRoles={['Administrator', 'Employee', 'Customer']} 
                    accessRoles={accessRoles} 
                  />}
                />
                <Route 
                  path={'/pageSixteen'} 
                  element={<PrivateRoute 
                    element={<ThreeMachineDashboard client={client} />} 
                    requiredRoles={['Administrator', 'Employee', 'Customer']} 
                    accessRoles={accessRoles} 
                  />}
                />
                <Route 
                  path={'/pageSeventeen'} 
                  element={<PrivateRoute 
                    element={<ThreeMachineDashboard client={client} />} 
                    requiredRoles={['Administrator', 'Employee', 'Customer']} 
                    accessRoles={accessRoles} 
                  />}
                />
                <Route 
                  path={'/pageEighteen'} 
                  element={<PrivateRoute 
                    element={<ThreeMachineDashboard client={client} />} 
                    requiredRoles={['Administrator', 'Employee', 'Customer']} 
                    accessRoles={accessRoles} 
                  />}
                />
                <Route 
                  path={'/serverWallLegend'} 
                  element={<PrivateRoute 
                    element={<ServerWallLegend client={client} />} 
                    requiredRoles={['Administrator', 'Employee', 'Customer']} 
                    accessRoles={accessRoles} 
                  />}
                />
                <Route
                  path={'/portal'}
                  element={<PrivateRoute 
                    element={<Portal client={client} />} 
                    requiredRoles={['Administrator', 'Employee', 'Customer']} 
                    accessRoles={accessRoles} 
                  />}
                />
              </Routes>
              <Footer client={client}/>
            </Router>
          </LocalizationProvider>
        </div>
      ) : (<div></div>)
    )
}


export default App
