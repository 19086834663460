import React, { useState, useEffect } from 'react'
import Grid from '@mui/material/Grid'
import MachineCard from './MachineCard'
import { animated, useTrail } from "react-spring"
import { getScreenSizeCategory } from './serverWallUtils'

const ThreeMachinePage = ({ client }) => {
    
    const [data, setData] = useState([])
    const [error, setError] = useState(null)
    const [startIndex, setStartIndex] = useState(0)
    const cardsPerSet = 12
    const routeIP = window.location.hostname
    const routePath = window.location.pathname
    const pageToRequest = routePath.slice(1)
    
    const fetchData = async () => {
        try {
            const refreshed = await client.updateToken(5)
            //await fetch(`http://${routeIP}:5000/api/serverWallData`, { 
            await fetch(`https://flaskservers.randsmachine.com:5000/api/serverWallData`, { 
                method:'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    page: pageToRequest,
                    token: client.token
                })
            }).then (response => {
                if (!response.ok) {
                    throw new Error('Request failed')
                }

                return response.json()
            }).then (data =>{setData(data)})
        } catch (error) {
            setError(error.message)
        }
    }

    const fadeTrail = useTrail(data.length, {
        config: { mass: 1, tension: 300, friction: 100 },
        from: { opacity: 0, transform: 'translate3d(0, 20px, 0)' },
        opacity: true ? 1 : 0,
        transform: true ? 'translate3d(0, 0, 0)' : 'translate3d(0, 20px, 0)',
    })
    
    useEffect((totalCards) => {
        fetchData(); // Fetch data on initial render
        const dataIntervalId = setInterval(() => {
          fetchData(); // Fetch data every 3 seconds
        }, 3000)
        return () => {
            clearInterval(dataIntervalId); // Clear data interval on component unmount
        }

    }, [routePath])

    if (error) {
        setTimeout(() => {
            window.location.reload()
        }, 3000)
        return <div style={{
            display: 'flex',
            alignContent: "baseline",
            marginInline: 'auto',
            maxWidth: '1920px',
            minHeight: 'calc(100vh - 63.59px)'
        }}>Error: {error}</div>
    }

    const endIndex = Math.min(startIndex + cardsPerSet, data.length)
    const currentData = data.slice(startIndex, endIndex)
    const machineCards = fadeTrail.map((style, index) => {
        if (index < currentData.length) {
            const machine = currentData[index]

            return (
                <Grid item xs={12} sm={6} md={4} lg={4} key={machine.name} sx={{ paddingBottom: 2 }}>
                    <animated.div style={style}>
                        <MachineCard
                            name={machine.name}
                            execution={
                                machine.execution["#text"] === "INTERRUPTED" ||
                                machine.execution["#text"] === "READY" || 
                                machine.execution["#text"] === "PROGRAM_STOPPED"
                                ? "Idle": machine.execution["#text"] === "STOPPED"
                                ? "Alarm" : machine.execution["#text"] 
                            }
                            program={machine.program["#text"]}
                            pathFeedrateOverride={
                                machine.pathFeedrateOverride["#text"] === "" ||
                                machine.pathFeedrateOverride["#text"] === "UNAVAILABLE"
                                ? "0"
                                : machine.pathFeedrateOverride["#text"]
                            }
                            pathFeedrate={machine.pathFeedrate["#text"]}
                            operatingTime={machine.accumulatedTime[1]["#text"]}
                            spindleRunTime={machine.accumulatedTime[7]["#text"]}
                            spindleRunTimePerMonth={machine.spindleRunTimePerMonth}
                            pathPosition={machine.pathPosition["#text"]}
                            spindleStartTimePerProgram={
                                machine.spindleStartTimePerProgram["#text"]
                            }
                        />
                    </animated.div>
                </Grid>
            )
        } else {
            return null
        }
    })

    return (
            <Grid container
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginInline: 'auto',
                    maxWidth: '1920px',
                    minHeight: 'calc(100vh - 63.59px)'
                }}
            >
                {machineCards}
            </Grid>
    )
}

export default ThreeMachinePage