import { React, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Typography, Dialog, DialogTitle, DialogContent, Stack, IconButton } from "@mui/material/"
import CloseIcon from '@mui/icons-material/Close'

export default function NotLoggedInDialog({ loginState, setLoginState, initLoginState }) {
    const navigate = useNavigate()

    const closeNotLoggedInDialog = () => {
        navigate('/VisitorSignInMenu')
    }

    useEffect(() => {
        const timer = setTimeout(() => {
            navigate('/VisitorSignInMenu')
        }, 5000)

        return () => {
            clearTimeout(timer)
        }// eslint-disable-next-line
    }, [])

    return (
        <Dialog
            open={!loginState.isLoggedIn}
            onClose={closeNotLoggedInDialog}
            fullWidth
            maxWidth="xs"
        >
            <DialogTitle sx={{ backgroundColor: '#0e4780', color: 'white' }}>
                {loginState.visitorName}
                <IconButton
                    size='small'
                    onClick={closeNotLoggedInDialog}
                    sx={{ float: 'right', color: 'white' }}
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers sx={{ display: 'flex', justifyContent: 'center' }}>
                <Stack spacing={2}>
                    <Typography sx={{ fontSize: 22, textAlign: 'center' }}>You are not signed in</Typography>
                    <Typography sx={{ fontSize: 18, textAlign: 'center' }}>Redirecting you back to the main menu...</Typography>
                </Stack>
            </DialogContent>
        </Dialog>
    )
}