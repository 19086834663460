export const pathPositionStyle20 = {
    fontSize: "20px",
    fontWeight: 650,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: '"Segoe UI Symbol"',
    background: '#FFFFFF'
}

export const timeStyle18 = {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontWeight: 550,
    fontSize: "18px",
    lineHeight: 1.5
}

export const timeStyle20 = {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontWeight: 550,
    fontSize: '20px',
    lineHeight: 1.5,
    letterSpacing: '0.00938em'
}

const fileTypeMap = new Map([
    ['n', -4],
    ['h', -2], 
    ['c', -3]
])

export const parseProgramName = (program) => {
    if (typeof(program) !== 'string') return ''
    const sliceValue = fileTypeMap.get(program.charAt(program.length - 1).toLowerCase())
    const truncatedProgramName = Number.isFinite(sliceValue) && program.charAt(program.length + sliceValue) === '.' ? program.slice(0, sliceValue) : program
    if (truncatedProgramName.length > 22) {
        let oversizedValue = truncatedProgramName.length - 22
        return truncatedProgramName.slice(oversizedValue)
    }

    return truncatedProgramName
}

export const getScreenSizeCategory = (screenSize) => {

    if (screenSize >= 1260) {
        return [
            { 
                fontSize: "38px", 
                margin: 1, 
                fontWeight: 600, 
                fontFamily: '"Segoe UI Symbol"' 
            },
            {
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                fontWeight: 550,
                fontSize: '20px',
                lineHeight: 1.5,
                letterSpacing: '0.00938em'
            },
            {
                fontSize: "20px",
                fontWeight: 650,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: '"Segoe UI Symbol"',
                background: '#FFFFFF'
            },
            {
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                fontWeight: 550,
                fontSize: '17px',
                lineHeight: 1.5,
                paddingTop: '2px'
            },
            {
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                fontWeight: 550,
                fontSize: '18px',
                background: '#FFFFFF'
            },
            {
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                fontWeight: 550,
                fontSize: '20px',
                background: '#FFFFFF'
            },
            {
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                fontWeight: 550,
                fontSize: '20px',
            },
            { 
                maxHeight: 380, 
                minHeight: 348, 
                backgroundColor: 'white' 
            },
            {
                color: "white", 
                height: "100%", 
                fontSize: 18
            }
        ]
    } else if (screenSize >= 1050) {
        return [
            { 
                fontSize: 30, 
                margin: 1, 
                fontWeight: 600, 
                fontFamily: '"Segoe UI Symbol"' 
            },
            {
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                fontWeight: 550,
                fontSize: 19,
                lineHeight: 1.5,
                letterSpacing: '0.00938em'
            },
            {
                fontSize: 16,
                fontWeight: 650,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: '"Segoe UI Symbol"',
                background: '#FFFFFF'
            },
            {
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                fontWeight: 550,
                fontSize: 16,
                lineHeight: 1.5
            },
            {
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                fontWeight: 550,
                fontSize: '16px',
                background: '#FFFFFF'
            },
            {
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                fontWeight: 550,
                fontSize: 17,
                background: '#FFFFFF'
            },
            {
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                fontWeight: 550,
                fontSize: 16,
                background: '#FFFFFF'
            },
            { 
                maxHeight: 300, 
                minHeight: 250, 
                borderTop: 1, 
                borderBottom: 1, 
                backgroundColor: 'white' 
            },
            {
                color: "white", 
                height: "100%", 
                fontSize: 18
            }
        ]
    } else if (screenSize >= 768) {
        return [
            { 
                fontSize: 26, 
                margin: 1, 
                fontWeight: 650, 
                fontFamily: '"Segoe UI Symbol"' 
            },
            {
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                fontWeight: 550,
                fontSize: 16,
                lineHeight: 1.5,
                letterSpacing: '0.00938em'
            },
            {
                fontSize: 13,
                fontWeight: 650,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: '"Segoe UI Symbol"',
                background: '#FFFFFF'
            },
            {
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                fontWeight: 550,
                fontSize: 15,
                lineHeight: 1.5
            },
            {
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                fontWeight: 550,
                fontSize: 15,
                background: '#FFFFFF'
            },
            {
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                fontWeight: 550,
                fontSize: 15,
                background: '#FFFFFF'
            },
            {
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                fontWeight: 800,
                fontSize: 12,
                background: '#FFFFFF'

            },
            { 
                maxHeight: 250, 
                minHeight: 200, 
                borderTop: 1, 
                borderBottom: 1, 
                backgroundColor: 'white' 
            },
            {
                color: "white", 
                height: "100%", 
                fontSize: 16
            }
        ]
    } else if (screenSize >= 576) {
        return [
            { 
                fontSize: "26px", 
                margin: 1, 
                fontWeight: 650, 
                fontFamily: '"Segoe UI Symbol"' 
            },
            {
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                fontWeight: 550,
                fontSize: '16px',
                lineHeight: 1.5,
                letterSpacing: '0.00938em'
            },
            {
                fontSize: "16px",
                fontWeight: 650,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: '"Segoe UI Symbol"',
                background: '#FFFFFF'
            },
            {
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                fontWeight: 550,
                fontSize: 14,
                lineHeight: 1.5
            },
            {
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                fontWeight: 550,
                fontSize: 16,
                background: '#FFFFFF'
            },
            {
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                fontWeight: 800,
                fontSize: 14,
                background: '#FFFFFF'
            },
            {
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                fontWeight: 800,
                fontSize: '10px',
                background: '#FFFFFF',
                paddingTop: '2px'
            },
            { 
                maxHeight: 250, 
                minHeight: 200, 
                borderTop: 1, 
                borderBottom: 1, 
                backgroundColor: 'white' 
            },
            {
                color: "white", 
                height: "100%", 
                fontSize: 14
            }
        ]
    } else {
        return [
            { 
                fontSize: "34px", 
                margin: 1, 
                fontWeight: 650, 
                fontFamily: '"Segoe UI Symbol"' 
            },
            {
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                fontWeight: 550,
                fontSize: '20px',
                lineHeight: 1.5,
                letterSpacing: '0.00938em'
            },
            {
                fontSize: "20px",
                fontWeight: 650,
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontFamily: '"Segoe UI Symbol"',
                background: '#FFFFFF'
            },
            {
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                fontWeight: 550,
                fontSize: "18px",
                lineHeight: 1.5
            },
            {
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                fontWeight: 550,
                fontSize: '18px',
                background: '#FFFFFF'
            },
            {
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                fontWeight: 550,
                fontSize: '18px',
                background: '#FFFFFF'
            },
            {
                fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
                fontWeight: 550,
                fontSize: '17px',
                background: '#FFFFFF'
            },
            { 
                maxHeight: 250, 
                minHeight: 200, 
                borderTop: 1, 
                borderBottom: 1, 
                backgroundColor: 'white' 
            },
            {
                color: "white", 
                height: "100%", 
                fontSize: 18
            }
        ]
    }
}