import * as React from 'react';
import {Box, Collapse, IconButton, Table, TableBody, TableContainer, FormHelperText, TableHead, TableRow, Typography, Paper } from '@mui/material/'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { StyledTableCell, StyledTableRow } from '../utils'

function Row(props) {
    const { row } = props
    const [open, setOpen] = React.useState(false)

    return (
        <React.Fragment>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <StyledTableCell>
                    <IconButton
                        aria-label="expand row"
                        size="small"
                        onClick={() => setOpen(!open)}
                    >
                        { open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon /> }
                    </IconButton>
                </StyledTableCell>
                <StyledTableCell component="th" scope="row">
                    {row.description}
                </StyledTableCell>
                <StyledTableCell align="right">{row.code}</StyledTableCell>
                <StyledTableCell align="right">{row.alarm}</StyledTableCell>
                <StyledTableCell align="right">{row.frequency}</StyledTableCell>
            </TableRow>
            <TableRow>
            <StyledTableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <Box sx={{ margin: 1 }}>
                    <Typography variant="h6" style={{ fontFamily: 'monospace' }} gutterBottom component="div">
                        History
                    </Typography>
                    <Table size="small" aria-label="purchases">
                        <TableHead>
                        <StyledTableRow>
                            <StyledTableCell>Date</StyledTableCell>
                            <StyledTableCell align="center">Long Description</StyledTableCell>
                        </StyledTableRow>
                        </TableHead>
                        <TableBody>
                        {row.history.map((historyRow) => (
                            <StyledTableRow key={historyRow.date}>
                                <StyledTableCell component="th" scope="row">
                                    {historyRow.date}
                                </StyledTableCell>
                                <StyledTableCell align="center">{historyRow.text}</StyledTableCell>
                            </StyledTableRow>
                        ))}
                        </TableBody>
                    </Table>
                    </Box>
                </Collapse>
            </StyledTableCell>
        </TableRow>
        </React.Fragment>
    )
}

export default function ErrorsTbl({machineStatistics, machineName}) {
    let rows = []
    for (const error of machineStatistics.errorCodeFrequency) {
        let history = []
        for (const errorHistory of machineStatistics.errors) {
            if (errorHistory.code === error.code) {
                history.push({
                    code: errorHistory.code,
                    description: errorHistory.description,
                    text: errorHistory.text,
                    date: errorHistory.date
                })
            }
        }
        rows.push({
            description: error.description, 
            code: error.code, 
            alarm: error.alarm, 
            frequency: error.frequency, 
            history: history
        })
    }
    if (rows.length === 0) {
        rows.push({
            description: "No information available", 
            code: "", 
            alarm: "", 
            frequency: "", 
            history: []
        })
        return (
            <React.Fragment>
                <Typography variant='h5' style={{ fontFamily: 'monospace' }} gutterBottom sx={{ textAlign: 'left' }}>Error Table</Typography>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>{machineName}</StyledTableCell>
                                <StyledTableCell>Description</StyledTableCell>
                                <StyledTableCell align="right">Code</StyledTableCell>
                                <StyledTableCell align="right">Alarm Value</StyledTableCell>
                                <StyledTableCell align="right">Frequency</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                            <StyledTableRow>
                                <StyledTableCell component="th" scope="row">
                                    No information available
                                </StyledTableCell>
                            </StyledTableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
                <FormHelperText style={{ textAlign: 'right' }}>ALARM_A > ALARM_D = Highest to lowest severity alarm</FormHelperText>
            </React.Fragment>
        )
    } else {
        return (
            <React.Fragment>
                <Typography variant='h5' style={{ fontFamily: 'monospace' }} gutterBottom sx={{ textAlign: 'left' }}>Error Table</Typography>
                <TableContainer component={Paper}>
                    <Table aria-label="collapsible table">
                        <TableHead>
                            <StyledTableRow>
                                <StyledTableCell>{machineName}</StyledTableCell>
                                <StyledTableCell>Description</StyledTableCell>
                                <StyledTableCell align="right">Code</StyledTableCell>
                                <StyledTableCell align="right">Alarm Value</StyledTableCell>
                                <StyledTableCell align="right">Frequency</StyledTableCell>
                            </StyledTableRow>
                        </TableHead>
                        <TableBody>
                        {rows.map((row) => (
                            <Row key={row.code} row={row}/>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <FormHelperText style={{ textAlign: 'right' }}>ALARM_A > ALARM_D = Highest to lowest severity alarm</FormHelperText>
            </React.Fragment>
        )
    }
}