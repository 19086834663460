import parseDailyRecordStats from './parseDailyRecordStats'
import parseErrorStats from './parseErrorStats'
import parseProgramStats from './parseProgramStats'
import parseGroupOverview from './parseGroupOverview'

export default function parseReportData(response, generateFormValues) {
    let machineData = []
    let parsedMachineData = []
    var groupData = {}
    var dailyRecordStats = {}
    var errorStats = {}
    var programStats = {}

    for(let i = 0; i < response.length; i++) {
        machineData.push({
            MachineName: response[i]['Machine'],
            DailyRecords: JSON.parse(response[i]['DailyRecords']),
            Errors: JSON.parse(response[i]['Errors']),
            Programs: JSON.parse(response[i]['Programs'])
        })
    }

    
    //This makes parsing the data more efficient. If its true that the user wants group data, then parse the data, otherwise don't.
    //This could be done for the other statistics
    if (generateFormValues.groupOverview === true) {
        groupData = parseGroupOverview(machineData)
    }

    for (let machine of machineData) {
        if (dailyRecordsBoolean(generateFormValues)) {
            dailyRecordStats = parseDailyRecordStats(machine)
        }

        if (generateFormValues.errorTableToggle === true) {
            errorStats = parseErrorStats(machine)
        }

        if (generateFormValues.programTableToggle === true) {
            programStats = parseProgramStats(machine)
        }
        
        parsedMachineData.push({
            machineName: machine.MachineName,
            dailyRecordStats,
            errorStats,
            programStats
        })
    }

    return {
        groupOverview: groupData,
        machines: parsedMachineData
    }
}

function dailyRecordsBoolean(generateFormValues) {
    return (
        generateFormValues.dailyRecordHLABarGraph === true ||
        generateFormValues.dailyRecordHLATable === true ||
        generateFormValues.dailyRecordsBarGraph === true ||
        generateFormValues.dailyRecordsToggle === true ||
        generateFormValues.AverageOperatingTimePieChart === true
    )
}