import { Box, Grid, Paper, Typography } from '@mui/material/'

const WcLegend = () => {

    return (
        <Paper elevation={1} sx={{ height: '100%' }}>
            <Box sx={{ padding: 1, paddingTop: 1.5, height: '100%' }}>
                <Paper elevation={4} sx={{ backgroundColor: '#0e4780' }}>
                    <Typography gutterBottom variant="h6" sx={{ textAlign: 'center', color:'white' }}>WC Legend</Typography>
                </Paper>
                <Grid container sx={{ display: 'flex' }}>
                    <Grid item sm={12} sx={{ paddingTop: 1, paddingBottom: 1 }}>
                        <Grid container>
                            <Grid item sm={2}></Grid>
                            <Grid item sx={{ backgroundColor: '#AEAAAA', border: 1 }} sm={2}></Grid>
                            <Grid item sm={1}></Grid>
                            <Grid item sx={{ justifyContent: 'center' }} sm={6}>
                                <Typography sx={{ display: 'flex', justifyContent: 'center' }}>Open</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={12} sx={{ paddingBottom: 1, paddingTop: .5 }}>
                        <Grid container>
                            <Grid item sm={2}></Grid>
                            <Grid item sx={{ backgroundColor: '#6DBD67', border: 1 }} sm={2}></Grid>
                            <Grid item sm={1}></Grid>
                            <Grid item sx={{ justifyContent: 'center' }} sm={6}>
                                <Typography sx={{ display: 'flex', justifyContent: 'center' }}>Started</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item sm={12} sx={{ paddingTop: .5 }}>
                        <Grid container>
                            <Grid item sm={2}></Grid>
                            <Grid item sx={{ backgroundColor: '#355DA5', border: 1 }} sm={2}></Grid>
                            <Grid item sm={1}></Grid>
                            <Grid item sx={{ justifyContent: 'center' }} sm={6}>
                                <Typography sx={{ display: 'flex', justifyContent: 'center' }}>Closed</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Paper>
    )
}

export default WcLegend