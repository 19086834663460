import { React, useState, useEffect } from 'react'
import { TextField, Link, Dialog, DialogTitle, DialogContent, Stack, FormControlLabel } from "@mui/material/" 
import { IconButton, FormControl, FormLabel, RadioGroup, Radio, Autocomplete } from "@mui/material/"
import LoadingButton from '@mui/lab/LoadingButton'
import MuiPhoneNumber from 'mui-phone-number';
import CloseIcon from '@mui/icons-material/Close'
import validator from 'validator'
import VisitorFODDialog from './PDFHandling/VisitorFOD'
import VisitorNDADialog from './PDFHandling/VisitorNDA'
import SubmissionResult from './SubmissionResult'
import RegistrationErrorDialog from './RegErrorDialog'
import { Popper } from '@mui/material'
import { styled } from '@mui/material/styles'

export default function CreateAccountDialog({ client, visitorRegDialogToggle, setVisitorRegDialogToggle }) {
    const routeIP = window.location.hostname
    const registrationFormInitState = {
        firstName: "",
        firstNameErrorValue: false,
        lastName: "",
        lastNameErrorValue: false,
        email: "",
        emailErrorValue: false,
        company: null,
        availableCompanies: [],
        companyErrorValue: false,
        phoneNumber: "",
        phoneNumberErrorValue: false,
        ndaDialogToggle: false,
        ndaOpened: 0,
        ndaOpenedErrorValue: false,
        ndaAcceptance: false,
        ndaAcceptanceErrorValue: false,
        fodDialogToggle: false,
        fodOpened: 0,
        fodOpenedErrorValue: false,
        fodAcceptance: false,
        fodAcceptanceErrorValue: false,
        usCitizen: 0,
        submitting: false,
        registrationErrorDialogToggle: false,
        registrationErrorArray: []

    }

    const LightGreyPopper = styled(Popper)({
        '& .MuiAutocomplete-paper': {
          backgroundColor: '#E5E4E2', // Light grey color
        },
    })

    const [registrationFormValues, setRegistrationFormValues] = useState(registrationFormInitState)
    const [submissionResult, setSubmissionResult] = useState({
        submissionRecieved: false,
        submissionDialogToggle: false,
        message: null, 
        result: null
    })

    const openFodDialog = () => {
        setRegistrationFormValues((prev) => ({...prev, fodDialogToggle: true}))
    }

    const openNdaDialog = () => {
        setRegistrationFormValues((prev) => ({...prev, ndaDialogToggle: true}))
    }

    useEffect(() => {
        fetchCompanies()
        // eslint-disable-next-line
    }, [])

    const fetchCompanies = async () => {
        try {
            await client.updateToken(5)
            const headers = { auth: client.token }
            //const response = await fetch(`http://${routeIP}:8000/utils/visitorRegistrationCompanies`,
            const response = await fetch(`https://flaskservers.randsmachine.com:8000/utils/visitorRegistrationCompanies`,
                { headers }
            )

            if (!response.ok) {
                const error = await response.json()
                throw new Error(error.message)
            }

            const data = await response.json()
            data.push("None", "Not Listed", "Computer Pro")
            setRegistrationFormValues((prev) =>({...prev, availableCompanies: data}))

        } catch (error) {
            alert(`Failed to fetch companies: ${error} `)
        }
    }

    const submitRegistrationForm = async () => {
        try {
            await client.updateToken(5)
            //const response = await fetch(`http://${routeIP}:8000/utils/submitUserRegistration`, {
            const response = await fetch(`https://flaskservers.randsmachine.com:8000/utils/submitUserRegistration`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    firstName: registrationFormValues.firstName,
                    lastName: registrationFormValues.lastName,
                    email: registrationFormValues.email,
                    company: registrationFormValues.company,
                    phoneNumber: registrationFormValues.phoneNumber,
                    ndaOpened: registrationFormValues.ndaOpened,
                    ndaAcceptance: registrationFormValues.ndaAcceptance,
                    fodOpened: registrationFormValues.fodOpened,
                    fodAcceptance: registrationFormValues.fodAcceptance,
                    usCitizen: registrationFormValues.usCitizen,
                    token: client.token
                })
            })

            if (!response.ok) {
                const error = await response.json()
                throw new Error(error.message)
            }

            const data = await response.json()
            if (!data['result']) {
                setSubmissionResult((prev) => ({...prev, submissionRecieved: true, result: data['result'], message: "Registration failed. Email has already been used."}))
            } else {
                setSubmissionResult((prev) => ({...prev, submissionRecieved: true, result: data['result'], message: "Registration success. You may now use your email to sign in."}))
            }

        } catch (error) {
            alert(error)
            setSubmissionResult((prev) => ({...prev, submissionRecieved: true, message: error, result: false}))
        }
    }

    const handleChange = (event) => {
        const name = event.target.name
        const value = event.target.value

        setRegistrationFormValues(prev => ({...prev, [name]: value}))
    }

    const closeVisitorRegDialog = () => {
        setRegistrationFormValues(registrationFormInitState)
        setVisitorRegDialogToggle(false)
    }

    const checkRegistrationFields = () => {
        var hasError = false
        var alertArray = []

        if (registrationFormValues.firstName === "") {
            alertArray.push("Enter your first name")
            setRegistrationFormValues(prev => ({...prev, firstNameErrorValue: true}))
            hasError = true
        }

        if (registrationFormValues.lastName === "") {
            alertArray.push("Enter your last name")
            setRegistrationFormValues(prev => ({...prev, lastNameErrorValue: true}))
            hasError = true
        }

        if (registrationFormValues.company === null) {
            alertArray.push("Select a value from the company drop down options")
            setRegistrationFormValues(prev => ({...prev, companyErrorValue: true}))
            hasError = true
        } 

        if (!validator.isEmail(registrationFormValues.email)) {
            alertArray.push("Enter your email")
            setRegistrationFormValues(prev => ({...prev, emailErrorValue: true}))
            hasError = true
        }
        
        if (["", "+", "+1"].includes(registrationFormValues.phoneNumber)) {
            alertArray.push("Enter your phone number")
            setRegistrationFormValues(prev => ({...prev, phoneNumberErrorValue: true}))
            hasError = true
        }

        if (!registrationFormValues.ndaAcceptance || !registrationFormValues.ndaOpened) {
            alertArray.push("Open, read, and accept the NDA document")
            if (!registrationFormValues.ndaAcceptance) {
                setRegistrationFormValues(prev => ({...prev, ndaAcceptanceErrorValue: true}))
            } 
            if (!registrationFormValues.ndaOpened) {
                setRegistrationFormValues(prev => ({...prev, ndaOpenedErrorValue: true}))
            }
            hasError = true
        }

        if (!registrationFormValues.fodAcceptance || !registrationFormValues.fodOpened) {
            alertArray.push("Open, read, and accept the FOD document")
            if (!registrationFormValues.fodAcceptance) {
                setRegistrationFormValues(prev => ({...prev, fodAcceptanceErrorValue: true}))
            }
            if (!registrationFormValues.fodOpened) {
                setRegistrationFormValues(prev => ({...prev, fodOpenedErrorValue: true}))
            }
            hasError = true
        }

        if (hasError) {
            setRegistrationFormValues(prev => ({...prev, registrationErrorArray: alertArray}))
            setRegistrationFormValues(prev => ({...prev, registrationErrorDialogToggle: true}))
        }

        return !hasError
    }


    return (
        <>
            <Dialog open={visitorRegDialogToggle} onClose={closeVisitorRegDialog} fullWidth>
            <DialogTitle 
                sx={{
                    textAlign: 'left',
                    backgroundColor: '#0e4780',
                    color: 'white'
                }}
            >
                Create account
                <IconButton
                    size='small'
                    onClick={closeVisitorRegDialog}
                    sx={{ float: 'right' }}
                >
                    <CloseIcon sx= {{ color: 'white' }} />
                </IconButton>
            </DialogTitle>
            <DialogContent dividers sx={{ padding: 4 }}>
                <Stack spacing={2}>
                    <TextField
                        name="firstName"
                        variant="outlined" 
                        label="First name"
                        autoComplete="new-password"
                        value={registrationFormValues.firstName}
                        error={registrationFormValues.firstNameErrorValue && registrationFormValues.firstName === ""}
                        onChange={handleChange}
                    />
                    <TextField
                        name="lastName"
                        variant="outlined" 
                        label="Last name"
                        autoComplete="new-password"
                        value={registrationFormValues.lastName}
                        error={registrationFormValues.lastNameErrorValue && registrationFormValues.lastName === ""}
                        onChange={handleChange}
                    
                    />
                    <Autocomplete
                        disablePortal
                        id="company"
                        value={registrationFormValues.company}
                        options={registrationFormValues.availableCompanies}
                        PopperComponent={LightGreyPopper}
                        isOptionEqualToValue={(option, value) => option === value}
                        onChange={(event, newValue) => {
                            setRegistrationFormValues((prev) => ({...prev, company: newValue}))
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name="company"
                                variant="outlined"
                                label="Company"
                                error={registrationFormValues.companyErrorValue && !registrationFormValues.availableCompanies.includes(registrationFormValues.company)}
                                helperText={
                                    <>
                                        <span style={{ margin: 0, fontSize: 16 }}>If company not listed, select 'Not Listed'</span>
                                        <br />
                                        <span style={{ margin: 0, fontSize: 16 }}>If not representing a company, select 'None'</span>
                                    </>
                                }
                            />
                        )}
                    />
                    <TextField
                        name="email"
                        variant="outlined" 
                        label="Email"
                        autoComplete="new-password"
                        value={registrationFormValues.email}
                        onChange={handleChange}
                        style={{ 'marginTop': '8px' }}
                        error={ !(validator.isEmail(registrationFormValues.email)) && registrationFormValues.emailErrorValue === true}
                    >
                    </TextField>
                    <MuiPhoneNumber 
                        defaultCountry={'us'}
                        onlyCountries={['us']}
                        name="phoneNumber"
                        variant="outlined" 
                        label="Phone number"
                        autoComplete="new-password"
                        value={registrationFormValues.phoneNumber}
                        error={registrationFormValues.phoneNumberErrorValue && ["", "+", "+1"].includes(registrationFormValues.phoneNumber)}
                        onChange={(value) => {setRegistrationFormValues(prev => ({...prev, phoneNumber: value}))}}
                    />
                    </Stack>
                    <Stack
                        direction="row"
                        spacing={2}
                        marginTop={4}
                        marginBottom={4}
                        sx={{ display: 'flex', justifyContent: 'space-between' }}
                    >
                        <FormControl>
                            <FormLabel>Agree to{' '}
                                <Link
                                    sx={{ 
                                        color: registrationFormValues.ndaOpenedErrorValue ? 'red': '',
                                        textDecorationColor: registrationFormValues.ndaOpenedErrorValue ? 'red': ''
                                    }}

                                    href="#" 
                                    onClick={(event) => {
                                        event.preventDefault()
                                        setRegistrationFormValues(prev => ({...prev, ndaOpened: 1}))
                                        setRegistrationFormValues(prev => ({...prev, ndaOpenedErrorValue: false}))
                                        openNdaDialog()
                                    }}
                                > 
                                    NDA
                                </Link>
                            </FormLabel>
                            <RadioGroup
                                value={registrationFormValues.ndaAcceptance}
                                onChange={(e) => {
                                    setRegistrationFormValues(prev => ({...prev, ndaAcceptance: parseInt(e.target.value)}))}} 
                                name="nda-radio-group"
                                row
                                sx={{ color: !registrationFormValues.ndaAcceptance && registrationFormValues.ndaAcceptanceErrorValue ? 'red': '' }}
                                error={!registrationFormValues.ndaAcceptance && registrationFormValues.ndaAcceptanceErrorValue}
                            >
                                <FormControlLabel 
                                    value={1} 
                                    control={<Radio />} 
                                    label="Yes" 
                                />
                                <FormControlLabel 
                                    value={0} 
                                    control={<Radio />} 
                                    label="No" 
                                />
                            </RadioGroup>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Agree to{' '}
                                <Link
                                    sx={{ 
                                        color: registrationFormValues.fodOpenedErrorValue ? 'red': '',
                                        textDecorationColor: registrationFormValues.fodOpenedErrorValue ? 'red': ''
                                    }}

                                    href="#" 
                                    onClick={(event) => {
                                        event.preventDefault()
                                        setRegistrationFormValues(prev => ({...prev, fodOpened: 1}))
                                        setRegistrationFormValues(prev => ({...prev, fodOpenedErrorValue: false}))
                                        openFodDialog()
                                    }}
                                > 
                                    FOD
                                </Link>
                            </FormLabel>
                            <RadioGroup
                                value={registrationFormValues.fodAcceptance}
                                onChange={(e) => {
                                    setRegistrationFormValues(prev => ({...prev, fodAcceptance: parseInt(e.target.value)}))}}
                                name="fod-radio-group"
                                row
                                sx={{ color: !registrationFormValues.fodAcceptance && registrationFormValues.fodAcceptanceErrorValue ? 'red': '' }}
                                error={!registrationFormValues.fodAcceptance && registrationFormValues.fodAcceptanceErrorValue}
                            >
                                <FormControlLabel 
                                    value={1} 
                                    control={<Radio />} 
                                    label="Yes" 
                                />
                                <FormControlLabel 
                                    value={0} 
                                    control={<Radio />} 
                                    label="No" 
                                />
                            </RadioGroup>
                        </FormControl>
                        <FormControl>
                            <FormLabel>Are you a U.S. citizen?</FormLabel>
                            <RadioGroup
                                value={registrationFormValues.usCitizen}
                                onChange={(e) => {
                                    setRegistrationFormValues(prev => ({...prev, usCitizen: parseInt(e.target.value)}))
                                }} 
                                name="us-citizen-radio-group"
                                row
                            >
                                <FormControlLabel 
                                    value={1} 
                                    control={<Radio />} 
                                    label="Yes" 
                                />
                                <FormControlLabel 
                                    value={0} 
                                    control={<Radio />} 
                                    label="No" 
                                />
                            </RadioGroup>
                        </FormControl>
                    </Stack>
                    <Stack>
                        <LoadingButton
                            loading={registrationFormValues.submitting}
                            id="submitButton"
                            sx={{ 
                                padding: 1.5, 
                                fontSize: 16, 
                                color:  'white', 
                                backgroundColor: '#0e4780', 
                                textTransform: 'none', 
                                '&:hover': { cursor: 'pointer', backgroundColor: '#003c6c' }, '& .MuiCircularProgress-root': { color: 'white' } 
                            }}
                            onClick={ async () => {
                                const button = document.querySelector("#submitButton")
                                button.disabled = true
                                setTimeout(() => button.disabled = false, 3000)
                                setRegistrationFormValues((prev) => ({...prev, submitting: true}))
                                const isValid = checkRegistrationFields()
                                if (isValid) {
                                    await new Promise(r => setTimeout(r, 1000))
                                    await submitRegistrationForm()
                                    setSubmissionResult((prev) => ({...prev, submissionDialogToggle: true}))
                                }
                                setRegistrationFormValues((prev) => ({...prev, submitting: false}))
                                
                            }}
                        >
                            Submit
                        </LoadingButton>
                    </Stack>
            </DialogContent>
        </Dialog>
        { registrationFormValues.ndaDialogToggle ? 
            <VisitorNDADialog 
                ndaDialogToggle={registrationFormValues.ndaDialogToggle} 
                setRegistrationFormValues={setRegistrationFormValues}
            /> : <></>
        }
        { registrationFormValues.fodDialogToggle ? 
            <VisitorFODDialog 
                fodDialogToggle={registrationFormValues.fodDialogToggle} 
                setRegistrationFormValues={setRegistrationFormValues}
            /> : <></>
        }
        { registrationFormValues.registrationErrorDialogToggle ? 
            <RegistrationErrorDialog 
                registrationFormValues={registrationFormValues} 
                setRegistrationFormValues={setRegistrationFormValues}
            />: <></>
        }
        { submissionResult.submissionDialogToggle ? 
            <SubmissionResult 
                submissionResult={submissionResult} 
                setSubmissionResult={setSubmissionResult} 
                closeVisitorRegDialog={closeVisitorRegDialog}
            />: <></>
        }
    </>
    )
}