//Global map for coloring cards
const cardColorMap = new Map ([
    ["Unavailable", "#bfbfbf"],
    ["Not_connected", "#bfbfbf"],
    ["Opt_stop", "#ffff33"],
    ["Feed_hold", "#ffff33"],
    ["Active", "#00802B"],
    ["Idle", "#ffff33"],
    ["Alarm", "#C23B22"],
    ["Program_completed", "#ffdf00"],
    ["Disconnected", "#bfbfbf"]
])

const convertMillimetersToInches = (millis) => {
    if (isNaN(millis / 25.4)) return 0 
    return millis / 25.4
}

const addMissingPathPosition = (path) => {
    var newArray = [...path]
    if (newArray.length < 3) {
        newArray.push(NaN)
        newArray = addMissingPathPosition(newArray)
    }
    
    return newArray
}

//Styles for building and departments. NOTE: SERVERWALL HAS ITS OWN UTILS FILE
export const pathPostionStyle14 = {
    fontSize: "14px",
    fontWeight: 650,
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontFamily: '"Segoe UI Symbol"',
    background: '#FFFFFF'
}

export const timeStyle16 = {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontWeight: 550,
    fontSize: "16px",
    lineHeight: 1.5
}

export const feedStyle16 = {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontWeight: 550,
    fontSize: '16px',
    lineHeight: 1.5,
    letterSpacing: '0.00938em'
}

export const handleExecutionName = (execution) => {
    if (typeof(execution) !== "string" ) return ""
    if (execution === "PROGRAM_OPTIONAL_STOP") return "Opt_stop"

    return execution[0].toUpperCase() + execution.slice(1).toLowerCase()
}

//Format seconds since program started
export const secondsToHms = (elapsedSeconds) => {
    const elapsedSecondsAsNumber = Number(elapsedSeconds)
    if (isNaN(elapsedSecondsAsNumber)) return ""

    var hours = Math.floor(elapsedSecondsAsNumber / 3600)
    var minutes = Math.floor(elapsedSecondsAsNumber % 3600 / 60)
    var seconds = Math.floor(elapsedSecondsAsNumber % 3600 % 60)

    var hDisplay = hours > 0 ? hours + " h, " : ""
    var mDisplay = minutes > 0 ? minutes + " m, " : ""
    var sDisplay = seconds > 0 ? seconds + " s" : ""

    return hDisplay + mDisplay + sDisplay 
}

//Format seconds to hours only
export const toHrs = (stringSeconds) => {
    const seconds = Number(stringSeconds)
    if (isNaN(seconds)) return 0
    Math.floor(seconds / 3600)
    return Math.floor(seconds / 3600) 
}

//Format path position
export const handlePathPosition = (pathPosition) => {
    if (pathPosition === "") return [0, 0, 0]
    var path = pathPosition.split(" ")
    if (path.length < 3) path = addMissingPathPosition(path)

    return path.map( position => convertMillimetersToInches(position))
}

//Make card a certain color depending on its execution status
export const determineCardColor = (execution) => {

    //This code is an apirl fools joke. Returns all green cards from in the hours of 6 and 7 am
    /****************************************************************************************/
    const date = new Date()
    if ((date.getMonth() === 3 && date.getDate() === 1 && [6, 7].includes(date.getHours()))) {
        return "#00802B"
    }
    /****************************************************************************************/
    return (cardColorMap.get(execution) || "white")
}